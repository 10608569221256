import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import paths from "../../services/paths-service";

export const mcSquareApiSlice = createApi({
  reducerPath: "mcSquareApi",
  baseQuery: fetchBaseQuery({
    // eslint-disable-next-line no-restricted-globals
    baseUrl: new URL("", location.origin).href,
    prepareHeaders: (headers, { getState }) => {
      const { authenticityToken } = getState().session;

      if (authenticityToken) {
        headers.set("X-CSRF-Token", authenticityToken);
      }

      return headers;
    },
  }),
  tagTypes: [
    "session",
    "lists",
    "mailchimpMergeFields",
    "mergeFieldMappings",
    "sync",
    "syncError",
    "completedRecommendations",
    "storeConflict",
  ],
  endpoints(builder) {
    return {
      loadSession: builder.query({
        query: () => paths.sessionPath,
        providesTags: () => ["session"],
      }),
      getMailchimpStoreConflict: builder.query({
        query: () => paths.mailchimpStoreConflictPath,
        providesTags: () => ["storeConflict"],
      }),
      deleteConflictingStore: builder.mutation({
        query: () => ({
          url: paths.mailchimpStoreConflictPath,
          method: "DELETE",
        }),
        invalidatesTags: () => ["storeConflict"],
      }),
      getMailchimpUsernameAvailability: builder.query({
        query: (username) =>
          paths.build(paths.mailchimpUsernameAvailabilityPath, { username }),
        transformResponse: (response) => response.accountAvailable,
      }),
      getMailchimpUsernameSuggestion: builder.query({
        query: (username) =>
          paths.build(paths.mailchimpUsernameSuggestionPath, { username }),
        transformResponse: (response) => response.usernames,
      }),
      getMailchimpSignupOptions: builder.query({
        query: () => paths.mailchimpSignupOptionsPath,
      }),
      signup: builder.mutation({
        query: (newAccountDetails) => ({
          url: paths.mailchimpSignupsPath,
          method: "POST",
          body: {
            ...newAccountDetails,
            first_name: newAccountDetails.firstName,
            last_name: newAccountDetails.lastName,
            organization_name: newAccountDetails.organizationName,
            address_line1: newAccountDetails.addressLine1,
            address_line2: newAccountDetails.addressLine2,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }),
      }),
      getLists: builder.query({
        query: () => paths.mailchimpListsPath,
        providesTags: () => ["lists"],
      }),
      createList: builder.mutation({
        query: (newListDetails) => ({
          url: paths.mailchimpListsPath,
          method: "POST",
          body: {
            name: newListDetails.newListName,
            double_optin: newListDetails.enableDoubleOptin,
            permission_reminder: newListDetails.permissionReminder,
            from_name: newListDetails.fromName,
          },
        }),
        invalidatesTags: () => ["lists"],
      }),
      saveSelectedList: builder.mutation({
        query: ({ remoteListId, listName }) => ({
          url: paths.mailchimpUserListPath,
          method: "PUT",
          body: {
            remote_list_id: remoteListId,
            name: listName,
          },
        }),
        invalidatesTags: () => ["session"],
      }),
      getMailchimpMergeFields: builder.query({
        query: () => paths.mailchimpMergeFieldsPath,
        providesTags: () => ["mailchimpMergeFields"],
      }),
      createMailchimpMergeField: builder.mutation({
        query: ({ name, tag }) => ({
          url: paths.mailchimpMergeFieldsPath,
          method: "POST",
          body: {
            name,
            tag,
          },
        }),
        invalidatesTags: () => ["mailchimpMergeFields"],
      }),
      createMailchimpLoyaltyMergeField: builder.mutation({
        query: () => ({
          url: paths.mailchimpLoyaltyMergeFieldPath,
          method: "POST",
        }),
        invalidatesTags: () => ["mailchimpMergeFields"],
      }),
      getMergeFieldMappings: builder.query({
        query: () => paths.mergeFieldMappingsPath,
        providesTags: () => ["mergeFieldMappings"],
      }),
      updateMergeFieldMappings: builder.mutation({
        query: ({ mergeFieldMappings, resyncCustomers }) => ({
          url: paths.mergeFieldMappingsPath,
          method: "PATCH",
          body: {
            merge_field_mappings: mergeFieldMappings.map(
              (mergeFieldMapping) => ({
                square_field: mergeFieldMapping.squareField,
                mailchimp_field: mergeFieldMapping.mailchimpField,
                data_type: mergeFieldMapping.dataType,
              })
            ),
            resync_customers: resyncCustomers,
          },
        }),
        invalidatesTags: () => ["mergeFieldMappings", "session"],
      }),
      getSquareCustomAttributeDefinitions: builder.query({
        query: () => paths.squareCustomAttributeDefinitionsPath,
        providesTags: () => ["squareCustomAttributeDefinitions"],
      }),
      kickoffSync: builder.mutation({
        query: ({ initial, cancelRunningSyncs = false }) => ({
          url: paths.syncsPath,
          method: "POST",
          body: {
            initial,
            cancelRunningSyncs,
          },
        }),
      }),
      getSync: builder.query({
        query: ({ id }) => paths.build(paths.syncPath, { id }),
        transformErrorResponse: () => null,
        providesTags: () => ["sync"],
      }),
      enableDashboard: builder.mutation({
        query: () => ({
          url: paths.dashboardEnablementPath,
          method: "POST",
        }),
        invalidatesTags: () => ["session"],
      }),
      disconnect: builder.mutation({
        query: (destroyRemoteData) => ({
          url: paths.remoteServicesConnectionPath,
          method: "DELETE",
          body: {
            destroy_remote_data: destroyRemoteData,
          },
        }),
        invalidatesTags: () => ["session"],
      }),
      getSyncs: builder.query({
        query: ({ page }) => ({
          url: paths.syncsPath,
          method: "GET",
          params: { page },
        }),
      }),
      getSyncErrors: builder.query({
        query: ({ syncId, includeResolved, page }) => ({
          url: paths.build(paths.syncSyncErrorsPath, { sync_id: syncId }),
          method: "GET",
          params: { page, include_resolved: includeResolved },
        }),
        providesTags: ["syncError"],
      }),
      retrySyncError: builder.mutation({
        query: (syncErrorId) => ({
          url: paths.build(paths.syncErrorSyncErrorRetriesPath, {
            sync_error_id: syncErrorId,
          }),
          method: "POST",
        }),
        invalidatesTags: ["syncError"],
      }),
      getOverviewStats: builder.query({
        query: () => ({
          url: paths.overviewStatPath,
          method: "GET",
        }),
      }),
      getCompletedRecommendations: builder.query({
        query: () => ({
          url: paths.completedRecommendationPath,
          method: "GET",
        }),
        providesTags: ["completedRecommendations"],
      }),
      updateCompletedRecommendations: builder.mutation({
        query: ({ completedRecommendations }) => ({
          url: paths.completedRecommendationPath,
          body: {
            completed_recommendations: completedRecommendations,
          },
          method: "PATCH",
        }),
        invalidatesTags: ["completedRecommendations"],
      }),
      getFeatureEnabled: builder.query({
        query: ({ feature }) => ({
          url: paths.build(paths.featureEnablementPath, { feature }),
          method: "GET",
        }),
      }),
      submitFeedback: builder.mutation({
        query: ({ rating, comments, email, allowContact }) => ({
          url: paths.feedbacksPath,
          body: {
            rating,
            comments,
            email,
            allow_contact: allowContact,
          },
          method: "POST",
        }),
        invalidatesTags: ["syncError"],
      }),
    };
  },
});

export const {
  useLoadSessionQuery,
  useGetMailchimpStoreConflictQuery,
  useDeleteConflictingStoreMutation,
  useGetMailchimpUsernameAvailabilityQuery,
  useGetMailchimpUsernameSuggestionQuery,
  useGetMailchimpSignupOptionsQuery,
  useSignupMutation,
  useGetListsQuery,
  useCreateListMutation,
  useSaveSelectedListMutation,
  useGetMailchimpMergeFieldsQuery,
  useCreateMailchimpMergeFieldMutation,
  useCreateMailchimpLoyaltyMergeFieldMutation,
  useGetMergeFieldMappingsQuery,
  useUpdateMergeFieldMappingsMutation,
  useGetSquareCustomAttributeDefinitionsQuery,
  useKickoffSyncMutation,
  useGetSyncQuery,
  useEnableDashboardMutation,
  useDisconnectMutation,
  useGetSyncsQuery,
  useGetSyncErrorsQuery,
  useRetrySyncErrorMutation,
  useGetOverviewStatsQuery,
  useGetCompletedRecommendationsQuery,
  useUpdateCompletedRecommendationsMutation,
  useGetFeatureEnabledQuery,
  useSubmitFeedbackMutation,
} = mcSquareApiSlice;
