import React from "react";
import PropTypes from "prop-types";

const NotAllowedIcon = ({
  alt = "Not Allowed",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="NotAllowedIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="NotAllowedIcon">{alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zm-2 0a9 9 0 01-14.618 7.032l12.65-12.65A8.962 8.962 0 0121 12zM4.968 17.618l12.65-12.65a9 9 0 00-12.65 12.65z"
    />
  </svg>
);

NotAllowedIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default NotAllowedIcon;
