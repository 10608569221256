/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import AnalyticsIcon from "./icons/analytics-icon";
import Modal from "./modal";
import SpinnerLabel from "./spinner-label";
import remoteErrors from "../services/remote-errors";
import { MUTATION_RESULT_PROP_TYPES } from "../constants/proptypes";

const FeedbackForm = ({
  visible = false,
  submitFeedback,
  submitFeedbackResult,
}) => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const fieldHasErrors = (field) =>
    errors[field] || remoteErrors(submitFeedbackResult, field);

  if (!visible) return null;

  return (
    <>
      <div className="feedback-link">
        <AnalyticsIcon />
        <button
          type="button"
          className="link"
          onClick={() => setShowFeedbackDialog(true)}
        >
          Help us by providing your <span>Feedback.</span>
        </button>
      </div>

      {showFeedbackDialog && (
        <Modal
          isOpen={showFeedbackDialog}
          close={() => setShowFeedbackDialog(false)}
          modalClassName="modal-large"
          showClose
        >
          <div className="feedback-form-dialog-content">
            {submitFeedbackResult.isSuccess ? (
              <div className="feedback-thank-you">
                <h1>Thank-you for your feedback!</h1>
                <div>
                  We value your thoughts; your response will help us improve our
                  products and services.
                </div>
                <img
                  srcSet="/images/illustration-complete-1x.jpg, /images/illustration-complete-2x.jpg 2x, /images/illustration-complete-3x.jpg 3x"
                  src="/images/illustration-complete-3x.jpg"
                  alt="A cartoon hand giving a thumbs up with extra thumbs"
                />
              </div>
            ) : (
              <div>
                <h1>Your input is valuable to us</h1>
                <div>Please take a moment to share your feedback.</div>

                <form
                  className="feedback-form"
                  onSubmit={handleSubmit(submitFeedback)}
                >
                  <div className="wink-form-field-container">
                    <div className="wink-form-label-container">
                      <label
                        className="wink-form-label"
                        htmlFor="suggestions"
                        id="suggestions-label"
                      >
                        How would you rate the Square and Mailchimp integration?
                      </label>
                    </div>
                    <div className="feedback-ratings">
                      {/* eslint-disable react/no-array-index-key */}
                      {Array(11)
                        .fill(0)
                        .map((_, rating) => (
                          <label
                            className="stacked-radio-label"
                            key={`rating-${rating}`}
                          >
                            <input
                              type="radio"
                              className={`wink-form-control wink-form-control-radio ${
                                fieldHasErrors("rating")
                                  ? "wink-form-field-error"
                                  : ""
                              }`}
                              {...register("rating", { required: true })}
                              value={rating}
                              onChange={() => {
                                clearErrors("rating");
                              }}
                            />
                            {rating}
                          </label>
                        ))}
                      {/* eslint-enable react/no-array-index-key */}
                    </div>

                    <p
                      aria-describedby="email-label"
                      className="wink-form-description wink-form-description-error"
                    >
                      {errors.rating?.type === "required" &&
                        "Please providing a rating."}
                    </p>
                    <p
                      aria-describedby="suggestions-label"
                      className="wink-form-description wink-form-description-error"
                    >
                      {remoteErrors(submitFeedbackResult, "rating")}
                    </p>
                  </div>

                  <div className="wink-form-field-container">
                    <div className="wink-form-label-container">
                      <label
                        className="wink-form-label"
                        htmlFor="suggestions"
                        id="suggestions-label"
                      >
                        Do you have any suggestions on how we can improve this
                        experience?
                      </label>
                    </div>
                    <textarea
                      className={`wink-form-field ${
                        fieldHasErrors("comments")
                          ? "wink-form-field-error"
                          : ""
                      }`}
                      placeholder="Enter comments"
                      {...register("comments", {})}
                    />
                    <p
                      aria-describedby="suggestions-label"
                      className="wink-form-description wink-form-description-error"
                    >
                      {remoteErrors(submitFeedbackResult, "comments")}
                    </p>
                  </div>

                  <div className="wink-form-field-container">
                    <div className="wink-form-label-container">
                      <label
                        className="wink-form-label"
                        htmlFor="email"
                        id="email-label"
                      >
                        Email
                      </label>
                    </div>
                    <input
                      className={`wink-form-field ${
                        fieldHasErrors("email") ? "wink-form-field-error" : ""
                      }`}
                      placeholder="Enter your email"
                      {...register("email", {
                        pattern: /^.+@[^.]+/,
                      })}
                    />
                    <p
                      aria-describedby="email-label"
                      className="wink-form-description wink-form-description-error"
                    >
                      {errors.email?.type === "pattern" &&
                        "Please enter a valid email address."}
                    </p>
                    <p
                      aria-describedby="suggestions-label"
                      className="wink-form-description wink-form-description-error"
                    >
                      {remoteErrors(submitFeedbackResult, "email")}
                    </p>
                  </div>

                  <div>
                    <label className="wink-form-control-label" id="disconnect">
                      <input
                        type="checkbox"
                        className="wink-form-control wink-form-control-checkbox"
                        {...register("allowContact")}
                      />
                      Yes, please contact me for my thoughts on Mailchimp for
                      Square.
                    </label>
                  </div>

                  <div className="wink-form-field-container action-buttons">
                    <button
                      type="submit"
                      className="wink-button wink-button-primary"
                    >
                      {submitFeedbackResult.isLoading ? (
                        <SpinnerLabel label="Submitting" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

FeedbackForm.propTypes = {
  visible: PropTypes.bool,
  submitFeedback: PropTypes.func.isRequired,
  submitFeedbackResult: MUTATION_RESULT_PROP_TYPES.isRequired,
};

export default FeedbackForm;
