import React from "react";
import ConflictingStoreModal from "../components/conflicting-store-modal";
import { UNKNOWN_USER } from "../constants/proptypes";
import {
  useDeleteConflictingStoreMutation,
  useDisconnectMutation,
  useGetMailchimpStoreConflictQuery,
  useLoadSessionQuery,
} from "../features/mc-square-api/mc-square-api-slice";

const ConflictingStoreModalContainer = () => {
  const { data: squareUser = UNKNOWN_USER, isLoading: isLoadingSession } =
    useLoadSessionQuery();
  const [triggerDisconnect, { isLoading: disconnecting }] =
    useDisconnectMutation();
  const [
    triggerDeleteConflictingStore,
    { isLoading: deletingConflictingStore },
  ] = useDeleteConflictingStoreMutation();
  const { data: conflictingStore, isLoading: isLoadingStoreConflict } =
    useGetMailchimpStoreConflictQuery(
      {},
      {
        skip: !squareUser.id,
      }
    );

  if (
    isLoadingSession ||
    isLoadingStoreConflict ||
    !squareUser.id ||
    !squareUser.mailchimpUser.id ||
    !conflictingStore
  )
    return null;

  return (
    <ConflictingStoreModal
      name={conflictingStore.name}
      url={conflictingStore.domain}
      disconnect={triggerDisconnect}
      disconnecting={disconnecting}
      triggerDeleteConflictingStore={triggerDeleteConflictingStore}
      deletingConflictingStore={deletingConflictingStore}
    />
  );
};

export default ConflictingStoreModalContainer;
