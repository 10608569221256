import React from "react";
import OverviewSummary from "../components/overview-summary";
import {
  useGetOverviewStatsQuery,
  useGetSyncQuery,
  useLoadSessionQuery,
} from "../features/mc-square-api/mc-square-api-slice";

const OverviewSummaryContainer = () => {
  const { data: latestSync, isLoading: loadingLatestSync } = useGetSyncQuery(
    { id: "latest" },
    {
      pollingInterval: 5000,
    }
  );

  const {
    data: { subscribers, customers, orders, products } = {},
    isLoading: loadingStats,
  } = useGetOverviewStatsQuery(null, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 5000,
  });

  const { data: squareUser } = useLoadSessionQuery();

  return (
    <OverviewSummary
      latestSync={latestSync}
      loadingLatestSync={loadingLatestSync}
      loadingStats={loadingStats}
      subscribers={subscribers}
      customers={customers}
      orders={orders}
      products={products}
      squareUser={squareUser}
    />
  );
};

export default OverviewSummaryContainer;
