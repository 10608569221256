import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CrossIcon from "./icons/cross-icon";

const Modal = ({
  children,
  isOpen,
  modalClassName = "",
  close,
  showClose = false,
}) => {
  const closeDialog = (e) => {
    if (e.key === "Escape") {
      close();
    }
  };

  useEffect(() => {
    document.activeElement.blur();

    if (isOpen) {
      document.body.style.overflowY = "hidden";
      document.addEventListener("keydown", closeDialog);
    } else {
      document.body.style.overflowY = "";
    }
    return () => {
      document.removeEventListener("keydown", closeDialog);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-container" role="dialog">
      <div className={`modal ${modalClassName}`}>
        {showClose && (
          <CrossIcon className="close-button" onClick={close} alt="Close" />
        )}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalClassName: PropTypes.string,
  close: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
};

export default Modal;
