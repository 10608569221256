import React from "react";
import { useSelector } from "react-redux";
import SetupFlow from "../components/setup-flow";
import { UNKNOWN_USER } from "../constants/proptypes";
import {
  useDisconnectMutation,
  useLoadSessionQuery,
} from "../features/mc-square-api/mc-square-api-slice";

const SetupFlowContainer = () => {
  const { data: squareUser = UNKNOWN_USER } = useLoadSessionQuery();
  const [triggerDisconnect, { isLoading: disconnecting }] =
    useDisconnectMutation();
  const withinPaymentReadUpdateFlow = useSelector(
    ({ mcSquare }) => mcSquare.withinPaymentReadUpdateFlow
  );

  return (
    <SetupFlow
      squareUser={squareUser}
      disconnect={triggerDisconnect}
      disconnecting={disconnecting}
      withinPaymentReadUpdateFlow={withinPaymentReadUpdateFlow}
    />
  );
};

export default SetupFlowContainer;
