import React from "react";
import { SQUARE_USER_PROP_TYPES } from "../constants/proptypes";

const UserIdsDisplay = ({ squareUser }) => {
  if (!squareUser.mailchimpUser) {
    return null;
  }

  return (
    <div className="user-ids-display">
      Reference Number: SQ{squareUser.mailchimpUser?.id}-
      {squareUser?.id || "0000"}
    </div>
  );
};

UserIdsDisplay.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES.isRequired,
};

export default UserIdsDisplay;
