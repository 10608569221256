import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import AudienceSelectionSetupContainer from "../containers/audience-selection-setup-container";
import { SQUARE_USER_PROP_TYPES, UNKNOWN_USER } from "../constants/proptypes";
import MergeFieldMappingsSetupContainer from "../containers/merge-field-mappings-setup-container";
import MailchimpAccountCreationContainer from "../containers/account-creation/mailchimp-account-creation-container";
import MailchimpAccountCreationSuccessContainer from "../containers/account-creation/mailchimp-account-creation-success-container";
import LoginsContainer from "../containers/logins-container";
import UpdateLoginsContainer from "../containers/update-logins-container";
import InitialSyncContainer from "../containers/initial-sync-container";
import SetupLayout from "./setup-layout";

const Logins = ({ withinPaymentReadUpdateFlow }) =>
  withinPaymentReadUpdateFlow ? <UpdateLoginsContainer /> : <LoginsContainer />;

Logins.propTypes = {
  withinPaymentReadUpdateFlow: PropTypes.bool.isRequired,
};

const lastEnabledSetupStep = (squareUser) =>
  squareUser.enabledSetupSteps[squareUser.enabledSetupSteps.length - 1] || "";

const Audience = ({ squareUser }) =>
  squareUser.enabledSetupSteps.includes("audience") ? (
    <AudienceSelectionSetupContainer />
  ) : (
    <Navigate to={`/${lastEnabledSetupStep(squareUser)}`} />
  );

Audience.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES.isRequired,
};

const MergeFieldMappings = ({ squareUser }) =>
  squareUser.enabledSetupSteps.includes("merge_field_mappings") ? (
    <MergeFieldMappingsSetupContainer />
  ) : (
    <Navigate to={`/${lastEnabledSetupStep(squareUser)}`} />
  );

MergeFieldMappings.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES.isRequired,
};

const Sync = ({ squareUser }) =>
  squareUser.enabledSetupSteps.includes("sync") ? (
    <InitialSyncContainer />
  ) : (
    <Navigate to={`/${lastEnabledSetupStep(squareUser)}`} />
  );

Sync.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES.isRequired,
};

const SetupFlow = ({
  squareUser = UNKNOWN_USER,
  disconnect,
  disconnecting = false,
  withinPaymentReadUpdateFlow,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (squareUser.id) {
      const setupStep =
        squareUser.enabledSetupSteps[squareUser.enabledSetupSteps.length - 1];
      navigate(`/${setupStep}`);
    }
  }, [squareUser.enabledSetupSteps]);

  return useRoutes([
    {
      path: "/*",
      element: (
        <SetupLayout
          squareUser={squareUser}
          disconnecting={disconnecting}
          disconnect={disconnect}
          withinPaymentReadUpdateFlow={withinPaymentReadUpdateFlow}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <Logins withinPaymentReadUpdateFlow={withinPaymentReadUpdateFlow} />
          ),
        },
        {
          path: "authenticate/mailchimp/create",
          element: <MailchimpAccountCreationContainer />,
        },
        {
          path: "authenticate/mailchimp/success",
          element: <MailchimpAccountCreationSuccessContainer />,
        },
        {
          path: "audience",
          element: <Audience squareUser={squareUser} />,
        },
        {
          path: "merge_field_mappings",
          element: <MergeFieldMappings squareUser={squareUser} />,
        },
        {
          path: "sync",
          element: <Sync squareUser={squareUser} />,
        },
        {
          path: "*",
          element: <Navigate replace to="/" />,
        },
      ],
    },
  ]);
};

SetupFlow.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES,
  disconnecting: PropTypes.bool,
  disconnect: PropTypes.func.isRequired,
  withinPaymentReadUpdateFlow: PropTypes.bool.isRequired,
};

export default SetupFlow;
