const remoteErrors = (result, field) => {
  if (!result.isError || !result?.error?.data?.errors) {
    return null;
  }

  const { fullMessages } = result.error.data.errors;
  return fullMessages[field];
};

export default remoteErrors;
