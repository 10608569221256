import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import SyncDetailsContainer from "../containers/sync-details-container";
import SyncLogsTableContainer from "../containers/sync-logs-table-container";
import { SYNC_PROP_TYPES } from "../constants/proptypes";
import SpinnerLabel from "./spinner-label";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const trackManualSync = () =>
  ReactGA.event({
    category: "sync logs",
    action: "click",
    label: "run another sync",
  });

const SyncLogs = ({ latestSync = null, kickoffSync, kickingOffSync }) => {
  const resync = useQuery().has("resync");

  return (
    <>
      <div>
        <h1>Sync Logs</h1>
        <p>
          Syncs are triggered as data changes at Square. You can click on a row
          in this table for more details about the sync.
        </p>

        {resync && (
          <div>
            <button
              type="button"
              className="wink-button wink-button-primary"
              disabled={
                kickingOffSync ||
                (latestSync &&
                  latestSync?.status !== "succeeded" &&
                  latestSync?.status !== "failed")
              }
              onClick={() => {
                kickoffSync({ initial: false, cancelRunningSyncs: true });
                trackManualSync();
              }}
            >
              {kickingOffSync ? (
                <SpinnerLabel label="Run another sync" />
              ) : (
                "Run another sync"
              )}
            </button>
          </div>
        )}

        <SyncLogsTableContainer />
      </div>

      <SyncDetailsContainer />
    </>
  );
};

SyncLogs.propTypes = {
  latestSync: SYNC_PROP_TYPES,
  kickoffSync: PropTypes.func.isRequired,
  kickingOffSync: PropTypes.bool.isRequired,
};

export default SyncLogs;
