import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import SpinnerLabel from "../../components/spinner-label";
import {
  useGetMailchimpSignupOptionsQuery,
  useGetMailchimpUsernameAvailabilityQuery,
  useGetMailchimpUsernameSuggestionQuery,
  useSignupMutation,
} from "../../features/mc-square-api/mc-square-api-slice";
import TickCircleFillIcon from "../../components/icons/tick-circle-fill-icon";
import IconLabel from "../../components/icon-label";
import NotAllowedIcon from "../../components/icons/not-allowed-icon";
import remoteErrors from "../../services/remote-errors";

const trackAccountCreationSubmission = () =>
  ReactGA.event({
    category: "api account create",
    action: "click",
    label: "api account create finish",
  });

const MailchimpAccountCreationContainer = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: { username: "", country: "", addressLine2: "" },
  });
  const [triggerSignup, signupResult] = useSignupMutation();

  const [submissionErrored, setSubmissionErrored] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (signupResult.isSuccess) navigate("/authenticate/mailchimp/success");
  }, [signupResult.isSuccess]);

  useEffect(() => {
    setSubmissionErrored(signupResult.isError);
    if (signupResult.isError) {
      window.scrollTo(0, 0);
    }
  }, [signupResult.isError]);

  const fieldHasErrors = (field) =>
    errors[field] || remoteErrors(signupResult, field);

  const {
    data: { countries, states } = { countries: [], states: { ca: [], us: [] } },
    isFetching: fetchingMailchimpSignupOptions,
  } = useGetMailchimpSignupOptionsQuery();

  const username = watch("username");
  const country = watch("country");
  const { stateProvinceRequired, postalCodeRequired } =
    countries.find((option) => option.code === country) || {};
  const statesList = states[country.toLowerCase()] || [];

  const {
    data: usernameIsAvailable,
    isFetching: fetchingUsernameAvailability,
    error: usernameAvailabilityError,
  } = useGetMailchimpUsernameAvailabilityQuery(username, {
    skip: !username,
  });

  const { data: usernameSuggestions } = useGetMailchimpUsernameSuggestionQuery(
    username,
    {
      skip: !username,
    }
  );

  useEffect(() => {
    clearErrors("username");
  }, [fetchingUsernameAvailability]);

  useEffect(() => {
    if (!username || !usernameAvailabilityError) {
      clearErrors("username");
    } else if (usernameAvailabilityError) {
      setError("username", { type: "usernameAvailabilityError" });
    }
  }, [username, usernameAvailabilityError]);

  useEffect(() => {
    if (!username || usernameIsAvailable) {
      clearErrors("username");
    } else if (!usernameIsAvailable) {
      setError("username", { type: "usernameIsAvailable" });
    }
  }, [username, usernameIsAvailable]);

  return (
    <div>
      <form
        onSubmit={(event) => {
          handleSubmit(triggerSignup, () => {
            setSubmissionErrored(true);
          })(event);
          trackAccountCreationSubmission();
        }}
      >
        <h1>Create a Mailchimp Account</h1>
        <p>
          Already have a Mailchimp account?{" "}
          <button
            type="button"
            className="wink-button wink-button-tertiary"
            onClick={() => navigate("/")}
          >
            Log in instead.
          </button>{" "}
        </p>

        {submissionErrored && (
          <div className="wink-feedback wink-feedback-error">
            <NotAllowedIcon className="wink-feedback-icon" />
            <div className="wink-feedback-message">
              <h2 className="wink-text-tag">
                We could not create your account.
              </h2>
              <p className="wink-text-small">
                Please correct any errors below and try again.
              </p>
            </div>
          </div>
        )}

        <h2>Profile</h2>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="username"
              id="username-label"
            >
              Username
            </label>
            <span
              aria-describedby="username-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("username") ? "wink-form-field-error" : ""
            }`}
            {...register("username", {
              required: true,
            })}
          />
          {fetchingUsernameAvailability && (
            <SpinnerLabel label="Checking username availability..." />
          )}
          {usernameIsAvailable && !fetchingUsernameAvailability && username && (
            <div className="wink-form-description">
              <IconLabel
                icon={TickCircleFillIcon}
                label="Username is available"
              />
            </div>
          )}
          {errors.username?.type === "usernameAvailabilityError" && (
            <div className="wink-form-description wink-form-description-error">
              <div>
                Mailchimp usernames may only contain letters and numbers.
              </div>
            </div>
          )}
          {errors.username?.type === "usernameIsAvailable" && (
            <div className="wink-form-description wink-form-description-error">
              <IconLabel
                icon={NotAllowedIcon}
                label="Username is not available"
              />
              {usernameSuggestions && (
                <span>
                  Suggested usernames: {usernameSuggestions.join(", ")}
                </span>
              )}
            </div>
          )}
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.username?.type === "required" &&
              "Username is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "username")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="email" id="email-label">
              Email
            </label>
            <span
              aria-describedby="email-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("email") ? "wink-form-field-error" : ""
            }`}
            {...register("email", {
              required: true,
              pattern: /^.+@[^.]+/,
            })}
          />
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.email?.type === "required" && "Email is a required field."}
          </p>
          <p
            aria-describedby="email-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.email?.type === "pattern" &&
              "Please enter a valid email address."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "email")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="firstName"
              id="firstName-label"
            >
              First Name
            </label>
            <span
              aria-describedby="firstName-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("firstName") ? "wink-form-field-error" : ""
            }`}
            {...register("firstName", {
              required: true,
            })}
          />
          <p
            aria-describedby="firstName-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.firstName?.type === "required" &&
              "First Name is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "firstName")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="lastName"
              id="lastName-label"
            >
              Last Name
            </label>
            <span
              aria-describedby="lastName-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("lastName") ? "wink-form-field-error" : ""
            }`}
            {...register("lastName", {
              required: true,
            })}
          />
          <p
            aria-describedby="lastName-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.lastName?.type === "required" &&
              "Last Name is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "lastName")}
          </p>
        </div>

        <h2>Contact Information</h2>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="organizationName"
              id="organizationName-label"
            >
              Organization Name
            </label>
            <span
              aria-describedby="organizationName-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("organizationName") ? "wink-form-field-error" : ""
            }`}
            {...register("organizationName", {
              required: true,
            })}
          />
          <p
            aria-describedby="organizationName-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.organizationName?.type === "required" &&
              "Organization Name is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "organizationName")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="addressLine1"
              id="addressLine1-label"
            >
              Street Address 1
            </label>
            <span
              aria-describedby="addressLine1-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("addressLine1") ? "wink-form-field-error" : ""
            }`}
            {...register("addressLine1", {
              required: true,
            })}
          />
          <p
            aria-describedby="addressLine1-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.addressLine1?.type === "required" &&
              "Street Address 1 is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "addressLine1")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="addressLine2"
              id="addressLine2-label"
            >
              Street Address 2
            </label>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("addressLine2") ? "wink-form-field-error" : ""
            }`}
          />
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "addressLine2")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="city" id="city-label">
              City
            </label>
            <span
              aria-describedby="city-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("city") ? "wink-form-field-error" : ""
            }`}
            {...register("city", {
              required: true,
            })}
          />
          <p
            aria-describedby="addressLine1-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.city?.type === "required" && "City is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "city")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="country"
              id="country-label"
            >
              Country
            </label>
            <span
              aria-describedby="country-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          {fetchingMailchimpSignupOptions || !countries.length ? (
            <SpinnerLabel label="Loading..." />
          ) : (
            <select
              className={`wink-form-select ${
                fieldHasErrors("country") ? "wink-form-select-error" : ""
              }`}
              {...register("country", {
                required: true,
              })}
            >
              <option value="" aria-label="Blank Option">
                &nbsp;
              </option>
              {countries.map((country) => (
                <option
                  key={`country-${country.code}-${country.name}`}
                  value={country.code}
                >
                  {country.name}
                </option>
              ))}
            </select>
          )}
          <p
            aria-describedby="addressLine1-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.country?.type === "required" &&
              "Country is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "country")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="state" id="state-label">
              State/Province
            </label>
            {stateProvinceRequired && (
              <span
                aria-describedby="state-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            )}
          </div>
          {statesList.length ? (
            <select
              className={`wink-form-select ${
                fieldHasErrors("state") ? "wink-form-select-error" : ""
              }`}
              {...register("state", {
                required: stateProvinceRequired,
              })}
            >
              <option value="" aria-label="Blank Option">
                &nbsp;
              </option>
              {statesList.map((state) => (
                <option
                  key={`state-${state.abbreviation}`}
                  value={state.abbreviation}
                >
                  {state.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              className={`wink-form-field ${
                fieldHasErrors("state") ? "wink-form-field-error" : ""
              }`}
              {...register("state", {
                required: stateProvinceRequired,
              })}
            />
          )}
          <p
            aria-describedby="state-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.state?.type === "required" &&
              "State/Province is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "state")}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="zip" id="zip-label">
              Postal Code
            </label>
            {postalCodeRequired && (
              <span
                aria-describedby="zip-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            )}
          </div>
          <input
            className={`wink-form-field ${
              fieldHasErrors("zip") ? "wink-form-field-error" : ""
            }`}
            {...register("zip", {
              required: postalCodeRequired,
            })}
          />
          <p
            aria-describedby="state-label"
            className="wink-form-description wink-form-description-error"
          >
            {errors.zip?.type === "required" &&
              "Postal Code is a required field."}
          </p>
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {remoteErrors(signupResult, "zip")}
          </p>
        </div>

        <div className="wink-form-field-container action-buttons">
          <button
            type="submit"
            className="wink-button wink-button-primary"
            disabled={signupResult.isLoading}
          >
            {signupResult.isLoading ? (
              <SpinnerLabel label="Create Free Account" />
            ) : (
              "Create Free Account"
            )}
          </button>
        </div>

        <p>
          By continuing, you are creating a Mailchimp account, and you agree to
          Mailchimp’s{" "}
          <a
            href="https://mailchimp.com/legal/terms/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://mailchimp.com/legal/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>

        <p>
          &copy; 2001–2020 All Rights Reserved. Mailchimp&reg; is a registered
          trademark of The Rocket Science Group.{" "}
          <a
            href="https://mailchimp.com/legal/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Preferences
          </a>
          ,{" "}
          <a
            href="https://mailchimp.com/legal/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
          , and{" "}
          <a
            href="https://mailchimp.com/legal/terms/"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
          .
        </p>
      </form>
    </div>
  );
};

MailchimpAccountCreationContainer.propTypes = {};

export default MailchimpAccountCreationContainer;
