import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setAuthenticityToken } from "../features/session/session-slice";
import {
  setSquareMergeFields,
  setWithinPaymentReadUpdateFlow,
} from "../features/mc-square/mc-square-slice";
import { useLoadSessionQuery } from "../features/mc-square-api/mc-square-api-slice";
import { SQUARE_MERGE_FIELD, UNKNOWN_USER } from "../constants/proptypes";
import Dashboard from "../components/dashboard";
import SetupFlowContainer from "./setup-flow-container";
import ImpersonationWarningHeader from "../components/impersonation-warning-header";

const ApplicationContainer = ({ authenticityToken, squareMergeFields }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAuthenticityToken(authenticityToken));
    dispatch(setSquareMergeFields(squareMergeFields));
    dispatch(
      setWithinPaymentReadUpdateFlow(window.location.pathname === "/update")
    );
  }, []);

  const { data: squareUser = UNKNOWN_USER, isLoading } = useLoadSessionQuery();

  if (isLoading) return null;

  return (
    <>
      <ImpersonationWarningHeader
        squareUser={squareUser}
        authenticityToken={authenticityToken}
      />
      {squareUser.setupComplete && squareUser.showDashboard ? (
        <Dashboard />
      ) : (
        <SetupFlowContainer />
      )}
    </>
  );
};

ApplicationContainer.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  squareMergeFields: SQUARE_MERGE_FIELD.isRequired,
};

export default ApplicationContainer;
