import React from "react";
import UserIdsDisplay from "../components/user-ids-display";
import { UNKNOWN_USER } from "../constants/proptypes";
import { useLoadSessionQuery } from "../features/mc-square-api/mc-square-api-slice";

const UserIdsDisplayContainer = () => {
  const { data: squareUser = UNKNOWN_USER, isLoading } = useLoadSessionQuery();

  if (isLoading) return null;

  return <UserIdsDisplay squareUser={squareUser} />;
};

UserIdsDisplayContainer.propTypes = {};

export default UserIdsDisplayContainer;
