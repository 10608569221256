import React from "react";
import { useCreateMailchimpLoyaltyMergeFieldMutation } from "../features/mc-square-api/mc-square-api-slice";
import LoyaltyMergeFieldMappingValidation from "../components/loyalty-merge-field-mapping-validation";

const LoyaltyMergeFieldMappingValidationContainer = () => {
  const [
    triggerCreateMailchimpLoyaltyMergeField,
    {
      isLoading: creatingMailchimpLoyaltyMergeField,
      isError: errorCreatingMailchimpLoyaltyMergeField,
      isSuccess: creatingMailchimpLoyaltyMergeFieldIsSuccess,
    },
  ] = useCreateMailchimpLoyaltyMergeFieldMutation();

  return (
    <LoyaltyMergeFieldMappingValidation
      createMailchimpLoyaltyMergeField={triggerCreateMailchimpLoyaltyMergeField}
      creatingMailchimpLoyaltyMergeField={creatingMailchimpLoyaltyMergeField}
      creatingMailchimpLoyaltyMergeFieldIsSuccess={
        creatingMailchimpLoyaltyMergeFieldIsSuccess
      }
      errorCreatingMailchimpLoyaltyMergeField={
        errorCreatingMailchimpLoyaltyMergeField
      }
    />
  );
};

LoyaltyMergeFieldMappingValidationContainer.propTypes = {};

export default LoyaltyMergeFieldMappingValidationContainer;
