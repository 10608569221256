import React from "react";
import Disconnect from "../components/disconnect";
import { useDisconnectMutation } from "../features/mc-square-api/mc-square-api-slice";

const DisconnectContainer = () => {
  const [triggerDisconnect, { isLoading: disconnecting }] =
    useDisconnectMutation();

  return (
    <Disconnect disconnect={triggerDisconnect} disconnecting={disconnecting} />
  );
};

DisconnectContainer.propTypes = {};

export default DisconnectContainer;
