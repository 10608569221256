import React from "react";
import PropTypes from "prop-types";
import { SQUARE_USER_PROP_TYPES } from "../constants/proptypes";

const ImpersonationWarningHeader = ({ squareUser, authenticityToken }) => {
  if (squareUser.impersonating) {
    return (
      <div className="impersonating wink-feedback wink-feedback-error">
        <h1>You are impersonating a User!</h1>
        <table>
          <tbody>
            <tr>
              <th>Square User Id</th>
              <td>{squareUser.id} (our ID)</td>
            </tr>
            <tr>
              <th>Square User Name</th>
              <td>{squareUser.name}</td>
            </tr>
            <tr>
              <th>Mailchimp User ID</th>
              <td>{squareUser.mailchimpUser.id} (our ID)</td>
            </tr>
            <tr>
              <th>Mailchimp User Name</th>
              <td>
                {squareUser.mailchimpUser.firstName}{" "}
                {squareUser.mailchimpUser.lastName}
              </td>
            </tr>
            <tr>
              <th>Mailchimp User Nickname</th>
              <td>{squareUser.mailchimpUser.nickname}</td>
            </tr>
          </tbody>
        </table>

        <div>
          <form action="/user_impersonation" method="post">
            <input name="_method" type="hidden" value="delete" />
            <input
              name="authenticity_token"
              type="hidden"
              value={authenticityToken}
            />
            <button type="submit" className="wink-button wink-button-primary">
              Stop Impersonating This User
            </button>
          </form>
        </div>
      </div>
    );
  }

  return null;
};

ImpersonationWarningHeader.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default ImpersonationWarningHeader;
