import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RecommendationsChecklist from "./recommendations-checklist";
import FeedbackFormContainer from "../containers/feedback-form-container";
import OverviewSummaryContainer from "../containers/overview-summary-container";

const Overview = ({
  loadingCompletedRecommendations,
  updateCompletedRecommendations,
  completedRecommendations: initialCompletedRecommendations = [],
}) => {
  const [completedRecommendations, setCompletedRecommendations] = useState(
    // eslint-disable-next-line react/destructuring-assignment
    initialCompletedRecommendations
  );

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    setCompletedRecommendations(initialCompletedRecommendations);
    // eslint-disable-next-line react/destructuring-assignment
  }, [initialCompletedRecommendations]);

  return (
    <div>
      <h1>You&apos;ve connected to Square</h1>
      <div className="subtitle">Your data is synced in real time.</div>

      <OverviewSummaryContainer />

      <FeedbackFormContainer />

      {!loadingCompletedRecommendations && (
        <RecommendationsChecklist
          completedRecommendations={completedRecommendations}
          recordRecommendationCompletion={(recommendation) => {
            if (!completedRecommendations.includes(recommendation)) {
              const newCompletedRecommendations = [
                ...completedRecommendations,
                recommendation,
              ];
              setCompletedRecommendations(newCompletedRecommendations);
              updateCompletedRecommendations({
                completedRecommendations: newCompletedRecommendations,
              });
            }
          }}
        />
      )}
    </div>
  );
};

Overview.propTypes = {
  completedRecommendations: PropTypes.arrayOf(PropTypes.string),
  loadingCompletedRecommendations: PropTypes.bool.isRequired,
  updateCompletedRecommendations: PropTypes.func.isRequired,
};

export default Overview;
