import React from "react";
import { FLATTENED_SYNC_BATCH_PROP_TYPES } from "../constants/proptypes";
import TickCircleIcon from "./icons/tick-circle-icon";
import Spinner from "./spinner";
import friendlyTaskName from "../services/worker-translator";
import CrossCircleIcon from "./icons/cross-circle-icon";

const percentComplete = (syncBatchStatus) => {
  if (syncBatchStatus.total === null) return 100;

  return Math.floor(
    (1 - syncBatchStatus.pending / syncBatchStatus.total) * 100
  );
};

const progressLabel = (syncBatchStatus) => {
  if (
    ["in_progress", "retrying"].includes(syncBatchStatus.status) &&
    syncBatchStatus.total === null
  ) {
    return "Looking for data...";
  }

  if (syncBatchStatus.total === null) {
    return null;
  }

  const waitingForMoreDataLabel =
    syncBatchStatus.status === "in_progress" &&
    percentComplete(syncBatchStatus) === 100
      ? "- Looking for more data..."
      : "";

  return `(${syncBatchStatus.total - syncBatchStatus.pending} of ${
    syncBatchStatus.total
  }) ${waitingForMoreDataLabel}`;
};
const SyncBatchStatus = ({ syncBatchStatus }) => {
  const statusIcon = () => {
    if (syncBatchStatus.status === "failed") return <CrossCircleIcon />;
    if (syncBatchStatus.status === "in_progress") return <Spinner size="1em" />;

    return percentComplete(syncBatchStatus) === 100 ? (
      <TickCircleIcon />
    ) : (
      <Spinner size="1em" />
    );
  };

  return (
    <div className="constrained-container">
      <div>
        <div className="wink-form-field-container">
          <label
            htmlFor={syncBatchStatus.task}
            id={`${syncBatchStatus.task}-label`}
            className="wink-form-label"
          >
            {statusIcon()}
            &nbsp;
            {friendlyTaskName(syncBatchStatus.task)}
          </label>
          <progress
            id={syncBatchStatus.task}
            value={percentComplete(syncBatchStatus)}
            max="100"
            className="wink-form-progress"
          >
            {progressLabel(syncBatchStatus)}
          </progress>
          <p
            aria-describedby={`${syncBatchStatus.task}-label`}
            className="wink-form-description wink-nowrap"
          >
            {progressLabel(syncBatchStatus)}
          </p>
        </div>
      </div>
    </div>
  );
};

SyncBatchStatus.propTypes = {
  syncBatchStatus: FLATTENED_SYNC_BATCH_PROP_TYPES.isRequired,
};

export default SyncBatchStatus;
