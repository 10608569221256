import React from "react";
import { useDispatch } from "react-redux";
import { useRetrySyncErrorMutation } from "../features/mc-square-api/mc-square-api-slice";
import SyncError from "../components/sync-error";
import { SYNC_ERROR_PROP_TYPE } from "../constants/proptypes";
import { setSelectedSyncId } from "../features/mc-square/mc-square-slice";

const SyncErrorContainer = ({ syncError = null }) => {
  const dispatch = useDispatch();

  const [retrySyncError] = useRetrySyncErrorMutation();

  return (
    <SyncError
      syncError={syncError}
      retrySyncError={retrySyncError}
      setSelectedSyncId={(syncId) => dispatch(setSelectedSyncId(syncId))}
    />
  );
};

SyncErrorContainer.propTypes = {
  syncError: SYNC_ERROR_PROP_TYPE,
};

export default SyncErrorContainer;
