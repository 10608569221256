import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import ErrorBoundaryContainer from "containers/error-boundary-container";
import {
  createHashRouter,
  RouterProvider,
  ScrollRestoration,
  useRouteError,
} from "react-router-dom";
import paths from "../services/paths-service";
import { SQUARE_MERGE_FIELD } from "../constants/proptypes";
import store from "../store";
import ApplicationContainer from "../containers/application-container";

const ErrorPage = () => {
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

const GOOGLE_ANALYTICS_TRACKING_ID = "G-XL8613MS9T";
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

const App = ({
  authenticityToken,
  sessionPath,
  mailchimpUsernameAvailabilityPath,
  mailchimpUsernameSuggestionPath,
  mailchimpSignupOptionsPath,
  mailchimpSignupsPath,
  mailchimpListsPath,
  mailchimpUserListPath,
  squareMergeFields,
  mailchimpMergeFieldsPath,
  mailchimpLoyaltyMergeFieldPath,
  mergeFieldMappingsPath,
  syncsPath,
  syncPath,
  dashboardEnablementPath,
  remoteServicesConnectionPath,
  logoutPath,
  syncSyncErrorsPath,
  syncErrorSyncErrorRetriesPath,
  bugsnagApiKey = "",
  bugsnagReleaseStage,
  revision = "",
  overviewStatPath,
  completedRecommendationPath,
  featureEnablementPath,
  feedbacksPath,
  squareCustomAttributeDefinitionsPath,
  mailchimpStoreConflictPath,
}) => {
  paths.add({
    sessionPath,
    mailchimpUsernameAvailabilityPath,
    mailchimpUsernameSuggestionPath,
    mailchimpSignupOptionsPath,
    mailchimpSignupsPath,
    mailchimpListsPath,
    mailchimpUserListPath,
    mailchimpMergeFieldsPath,
    mailchimpLoyaltyMergeFieldPath,
    mergeFieldMappingsPath,
    syncsPath,
    syncPath,
    dashboardEnablementPath,
    remoteServicesConnectionPath,
    logoutPath,
    syncSyncErrorsPath,
    syncErrorSyncErrorRetriesPath,
    overviewStatPath,
    completedRecommendationPath,
    featureEnablementPath,
    feedbacksPath,
    squareCustomAttributeDefinitionsPath,
    mailchimpStoreConflictPath,
  });

  const router = createHashRouter(
    [
      {
        path: "/*",
        element: (
          <Provider store={store}>
            <ErrorBoundaryContainer
              bugsnagApiKey={bugsnagApiKey}
              bugsnagReleaseStage={bugsnagReleaseStage}
              revision={revision}
            >
              <ScrollRestoration />
              <ApplicationContainer
                authenticityToken={authenticityToken}
                squareMergeFields={squareMergeFields}
              />
            </ErrorBoundaryContainer>
          </Provider>
        ),
        errorElement: <ErrorPage />,
      },
    ],
    {
      future: {
        v7_normalizeFormMethod: true,
      },
    }
  );

  return <RouterProvider router={router} />;
};

App.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  sessionPath: PropTypes.string.isRequired,
  mailchimpUsernameAvailabilityPath: PropTypes.string.isRequired,
  mailchimpUsernameSuggestionPath: PropTypes.string.isRequired,
  mailchimpSignupOptionsPath: PropTypes.string.isRequired,
  mailchimpSignupsPath: PropTypes.string.isRequired,
  mailchimpListsPath: PropTypes.string.isRequired,
  mailchimpUserListPath: PropTypes.string.isRequired,
  squareMergeFields: SQUARE_MERGE_FIELD.isRequired,
  mailchimpMergeFieldsPath: PropTypes.string.isRequired,
  mailchimpLoyaltyMergeFieldPath: PropTypes.string.isRequired,
  mergeFieldMappingsPath: PropTypes.string.isRequired,
  syncsPath: PropTypes.string.isRequired,
  syncPath: PropTypes.string.isRequired,
  dashboardEnablementPath: PropTypes.string.isRequired,
  remoteServicesConnectionPath: PropTypes.string.isRequired,
  logoutPath: PropTypes.string.isRequired,
  syncSyncErrorsPath: PropTypes.string.isRequired,
  syncErrorSyncErrorRetriesPath: PropTypes.string.isRequired,
  bugsnagApiKey: PropTypes.string,
  bugsnagReleaseStage: PropTypes.string.isRequired,
  revision: PropTypes.string,
  overviewStatPath: PropTypes.string.isRequired,
  completedRecommendationPath: PropTypes.string.isRequired,
  featureEnablementPath: PropTypes.string.isRequired,
  feedbacksPath: PropTypes.string.isRequired,
  squareCustomAttributeDefinitionsPath: PropTypes.string.isRequired,
  mailchimpStoreConflictPath: PropTypes.string.isRequired,
};

export default App;
