import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import Header from "./header";
import SetupNavLink from "./setup-nav-link";
import paths from "../services/paths-service";
import SpinnerLabel from "./spinner-label";
import IconLabel from "./icon-label";
import ExitIcon from "./icons/exit-icon";
import { SQUARE_USER_PROP_TYPES, UNKNOWN_USER } from "../constants/proptypes";

const SetupFlowHeader = ({
  squareUser = UNKNOWN_USER,
  disconnecting = false,
  disconnect,
  withinPaymentReadUpdateFlow,
  pathname,
}) => {
  const trackCancelEvent = () =>
    ReactGA.event({
      category: {
        "/audience": "audience selection",
        "/merge_field_mappings": "field mappings",
        "/sync": "sync in progress",
      }[pathname],
      action: "click",
      label: "cancel setup",
    });

  const performDisconnect = async () => {
    await disconnect(false);
    trackCancelEvent();
    document.location.href = paths.logoutPath;
  };

  return (
    <Header>
      <div className="setup-header-items">
        <div>
          <div className="wink-h3 wink-nomargin">Square by Mailchimp</div>
          {!withinPaymentReadUpdateFlow && (
            <ul className="installation-flow-nav">
              <li>
                <button
                  type="button"
                  className={`link ${
                    squareUser.enabledSetupSteps.length === 0 ? "active" : ""
                  }`}
                  onClick={performDisconnect}
                  disabled={disconnecting}
                >
                  Connect accounts
                </button>
              </li>
              <li>
                <SetupNavLink
                  to="/audience"
                  enabled={squareUser.enabledSetupSteps.includes("audience")}
                >
                  Choose audience
                </SetupNavLink>
              </li>
              <li>
                <SetupNavLink
                  to="/merge_field_mappings"
                  enabled={squareUser.enabledSetupSteps.includes(
                    "merge_field_mappings"
                  )}
                >
                  Map fields
                </SetupNavLink>
              </li>
              <li>
                <SetupNavLink
                  to="/sync"
                  enabled={squareUser.enabledSetupSteps.includes("sync")}
                >
                  Sync
                </SetupNavLink>
              </li>
            </ul>
          )}
        </div>

        {squareUser.id && (
          <button
            type="button"
            className="wink-button wink-button-tertiary"
            onClick={performDisconnect}
            disabled={disconnecting}
          >
            {disconnecting ? (
              <SpinnerLabel label="Cancel" />
            ) : (
              <IconLabel label="Cancel" icon={ExitIcon} />
            )}
          </button>
        )}
      </div>
    </Header>
  );
};

SetupFlowHeader.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES,
  disconnecting: PropTypes.bool,
  disconnect: PropTypes.func.isRequired,
  withinPaymentReadUpdateFlow: PropTypes.bool.isRequired,
  pathname: PropTypes.oneOf([
    "/_=_",
    "/",
    "/audience",
    "/merge_field_mappings",
    "/sync",
  ]).isRequired,
};

export default SetupFlowHeader;
