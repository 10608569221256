import React from "react";
import PropTypes from "prop-types";

const HomeIcon = ({
  alt = "Home",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="HomeIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="HomeIcon">{alt}</title>

    <path d="M15 21v-8H9v8h6z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 9.52L12 .72 1 9.52V23h22V9.52zM3 21V10.48l9-7.2 9 7.2V21H3z"
    />
  </svg>
);

HomeIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default HomeIcon;
