export default (options) => ({
  container: (provided) => ({
    ...provided,
    marginTop: 'var(--spacing-1)',
    lineHeight: 'var(--line-height-text)',
    '& span': {
      border: '1px solid red',
    },
  }),
  control: (provided, state) => {
    const focusedStyles = {};
    if (state.isFocused) {
      if (options?.invalid) {
        focusedStyles.outline = '2px solid var(--text-color-error)';
      } else {
        focusedStyles.outline = '2px solid var(--brand-kale)';
      }
      focusedStyles.outlineOffset = '1px';
    }

    const border = options?.invalid
      ? {
          border: '1px solid var(--text-color-error)',
          ':hover': {
            border: '1px solid var(--text-color-error)',
            cursor: 'pointer',
          },
        }
      : {
          border: '1px solid var(--brand-peppercorn-65)',
          ':hover': {
            border: '1px solid var(--brand-peppercorn-65)',
            cursor: 'pointer',
          },
        };

    return {
      ...provided,
      ...focusedStyles,
      ...border,
      margin: '0',
      borderRadius: '0',
      boxShadow: 'none',
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginTop: 0,
    svg: {
      color: 'var(--brand-peppercorn)',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 'var(--spacing-2) 0 var(--spacing-2) var(--spacing-2)',
  }),
  menuList: (provided) => ({
    ...provided,
    '& div': { marginTop: 'auto' },
  }),
});
