import React from "react";
import PropTypes from "prop-types";

const CrossIcon = ({
  alt = "Cross",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="CircleCrossIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="CrossIcon">{alt}</title>
    <path d="M12 13.414l6.293 6.293 1.414-1.414L13.414 12l6.293-6.293-1.414-1.414L12 10.586 5.707 4.293 4.293 5.707 10.586 12l-6.293 6.293 1.414 1.414L12 13.414z" />
  </svg>
);

CrossIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default CrossIcon;
