import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import {
  PAGINATION_TYPE,
  SYNC_ERRORS_PROP_TYPES,
  SYNC_PROP_TYPES,
} from "../constants/proptypes";
import Modal from "./modal";
import SyncStatus from "./sync-status";
import MenuLeftIcon from "./icons/menu-left-icon";
import MenuRightIcon from "./icons/menu-right-icon";
import Feedback from "./feedback";
import SyncErrorContainer from "../containers/sync-error-container";

const SyncDetails = ({
  close,
  sync = null,
  syncErrors = null,
  pagination = null,
  setPage,
  includeResolved,
  setIncludeResolved,
  hasResolvedErrors = false,
}) => (
  <Modal isOpen modalClassName="sync-details" close={close} showClose>
    <h3>Sync Details</h3>

    {sync && (
      <div className="overall-status">
        {sync.status === "succeeded" && (
          <Feedback label="Success!" status="success">
            This sync was completed successfully.
          </Feedback>
        )}
        {sync.status === "failed" && (
          <Feedback label="Sync Failed" status="error">
            There were unexpected problems completing your sync. Some items have
            failed to sync. See below for more information.
          </Feedback>
        )}
        {sync.status === "retrying" && (
          <Feedback label="Retrying" status="warning">
            There were unexpected problems completing your sync. Some items are
            retrying. See below for more information.
          </Feedback>
        )}
        {sync.status === "canceled" && (
          <Feedback label="Sync Canceled" status="error">
            This sync was canceled before it finished.
          </Feedback>
        )}
      </div>
    )}

    <SyncStatus sync={sync} />

    <div>
      {(syncErrors?.length > 0 || hasResolvedErrors) && <h4>Alerts Summary</h4>}

      {hasResolvedErrors && (
        <label className="wink-form-control-label" id="disconnect">
          <input
            type="checkbox"
            className="wink-form-control wink-form-control-checkbox"
            checked={includeResolved}
            onChange={(e) => {
              setIncludeResolved(e.target.checked);
              setPage(1);
            }}
          />
          Show me alerts that have been resolved for this sync.
        </label>
      )}

      {syncErrors?.length > 0 && (
        <div>
          <div>
            Showing {pagination.to} of {pagination.count} alerts.
          </div>
          {syncErrors.map((syncError) => (
            <SyncErrorContainer key={syncError.id} syncError={syncError} />
          ))}
          {pagination.pages > 1 && (
            <div className="sync-logs-footer">
              <ReactPaginate
                forcePage={pagination.page - 1}
                containerClassName="pagination"
                pageCount={pagination.pages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                previousLinkClassName="wink-button"
                nextLinkClassName="wink-button"
                pageLinkClassName="wink-button"
                breakLinkClassName="wink-button"
                activeLinkClassName="wink-button-primary"
                disabledClassName="disabled"
                previousLabel={<MenuLeftIcon />}
                nextLabel={<MenuRightIcon />}
                onPageChange={({ selected }) => setPage(selected + 1)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  </Modal>
);

SyncDetails.propTypes = {
  close: PropTypes.func.isRequired,
  sync: SYNC_PROP_TYPES,
  syncErrors: SYNC_ERRORS_PROP_TYPES,
  pagination: PAGINATION_TYPE,
  setPage: PropTypes.func.isRequired,
  includeResolved: PropTypes.bool.isRequired,
  setIncludeResolved: PropTypes.func.isRequired,
  hasResolvedErrors: PropTypes.bool,
};

export default SyncDetails;
