import React from "react";
import PropTypes from "prop-types";

const InfoCircleIcon = ({
  alt = "Info",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="InfoCircleIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="InfoCircleIcon">{alt}</title>
    <path d="M9 12v-1l2-1h2v6l2 1v1H9v-1l2-1v-4H9zm2.75-4a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-2a9 9 0 100-18 9 9 0 000 18z"
    />
  </svg>
);

InfoCircleIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default InfoCircleIcon;
