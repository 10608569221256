import React from "react";
import ReactGA from "react-ga4";
import OverviewSummaryContainer from "../containers/overview-summary-container";

const trackVisitDashboard = () =>
  ReactGA.event({
    category: "dashboard",
    action: "click",
    label: "visit dashboard",
  });

const AllSet = () => (
  <div className="main-content">
    <h1>You&apos;ve connected to Square</h1>
    <div className="subtitle">Your data is synced in real time.</div>

    <OverviewSummaryContainer />

    <div className="checklist-complete">
      <img
        srcSet="/images/illustration-complete-1x.jpg, /images/illustration-complete-2x.jpg 2x, /images/illustration-complete-3x.jpg 3x"
        src="/images/illustration-complete-3x.jpg"
        alt="A cartoon hand giving a thumbs up with extra thumbs"
      />
      <h2>Thanks for updating!</h2>

      <div className="mt-7">
        <a
          href="https://admin.mailchimp.com/integrations/manage/?name=square"
          target="_blank"
          rel="noreferrer"
          className="wink-button wink-button-primary"
          onClick={trackVisitDashboard}
        >
          Return to Mailchimp
        </a>
      </div>
    </div>
  </div>
);

AllSet.propTypes = {};

export default AllSet;
