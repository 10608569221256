import React from "react";

import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./dashboard-layout";
import OverviewContainer from "../containers/overview-container";
import Settings from "./settings";
import SyncLogsContainer from "../containers/sync-logs-container";
import Support from "./support";
import AllSet from "./all-set";

const Dashboard = () =>
  useRoutes([
    {
      path: "/*",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <OverviewContainer />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "sync-logs",
          element: <SyncLogsContainer />,
        },
        { path: "support", element: <Support /> },
        { path: "all-set", element: <AllSet /> },
        {
          path: "*",
          element: <Navigate replace to="/" />,
        },
      ],
    },
  ]);

Dashboard.propTypes = {};

export default Dashboard;
