import React from "react";
import PropTypes from "prop-types";

const MenuDownIcon = ({
  alt = "Menu Down",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="MenuDownIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="MenuDownIcon">{alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.293 10.707l1.414-1.414L12 13.586l4.293-4.293 1.414 1.414L12 16.414l-5.707-5.707z"
    />
  </svg>
);

MenuDownIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default MenuDownIcon;
