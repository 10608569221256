import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import IconLabel from "./icon-label";
import ExitIcon from "./icons/exit-icon";
import SpinnerLabel from "./spinner-label";
import Modal from "./modal";
import paths from "../services/paths-service";

const ConflictingStoreModal = ({
  name,
  url,
  disconnect,
  disconnecting,
  triggerDeleteConflictingStore,
  deletingConflictingStore,
}) => {
  const [deleteStore, setDeleteStore] = useState(false);

  const trackCancelEvent = () =>
    ReactGA.event({
      category: "conflicting store",
      action: "click",
      label: "cancel setup",
    });

  const performDisconnect = async () => {
    await disconnect(false);
    trackCancelEvent();
    window.location.assign(paths.logoutPath);
  };

  return (
    <Modal isOpen modalClassName="modal-large" close={() => {}}>
      <h3>Whoops! A Square store already exists</h3>
      <p>
        A Square store with the address <strong>&quot;{url}&quot;</strong> has
        already been connected to your Mailchimp account. We can not connect two
        Square stores with the same address. The conflicting store name is{" "}
        <strong>{name}</strong>.
      </p>

      <p>
        Before proceeding, you will need to delete your old store data in
        Mailchimp.
      </p>

      <form>
        <div className="wink-form-control-container conflict-deletion-confirmation">
          <label className="wink-form-control-label" id="disconnect">
            <input
              type="checkbox"
              className="wink-form-control wink-form-control-checkbox"
              checked={deleteStore}
              onChange={(e) => {
                setDeleteStore(e.target.checked);
              }}
            />
            <span>
              Delete <strong>{name}</strong> Data at Mailchimp
            </span>
          </label>
          <p aria-describedby="disconnect" className="wink-form-description">
            Deleting your old store data in Mailchimp will delete the Square
            store association, all products and orders that have been syncing
            for this store. This will not impact any data in your Square
            account.
          </p>
        </div>

        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-secondary"
            onClick={performDisconnect}
            disabled={disconnecting || deletingConflictingStore}
          >
            {disconnecting ? (
              <SpinnerLabel label="Cancel Setup" />
            ) : (
              <IconLabel label="Cancel Setup" icon={ExitIcon} />
            )}
          </button>
          <button
            type="button"
            className="wink-button wink-button-primary"
            disabled={!deleteStore || deletingConflictingStore || disconnecting}
            onClick={async () => {
              await triggerDeleteConflictingStore();
            }}
          >
            {deletingConflictingStore ? (
              <SpinnerLabel label={`Delete ${name} data and continue setup`} />
            ) : (
              `Delete ${name} data and continue setup`
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

ConflictingStoreModal.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  disconnecting: PropTypes.bool.isRequired,
  disconnect: PropTypes.func.isRequired,
  triggerDeleteConflictingStore: PropTypes.func.isRequired,
  deletingConflictingStore: PropTypes.bool.isRequired,
};

export default ConflictingStoreModal;
