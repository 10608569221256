import React from "react";
import PropTypes from "prop-types";

const DeleteIcon = ({
  alt = "Delete",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="DeleteIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="DeleteIcon">{alt}</title>
    <path d="M8.982 19L7.65 9h2.018L11 19H8.982zm4 0l1.334-10h2.017L15 19h-2.018z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1h10v4h6v2h-1.991l-2.134 16H5.125L2.99 7H1V5h6V1zm8 2v2H9V3h6zm3.991 4H5.01l1.867 14h10.248l1.867-14z"
    />
  </svg>
);

DeleteIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default DeleteIcon;
