import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { UNKNOWN_USER } from "constants/proptypes";
import { useLoadSessionQuery } from "../features/mc-square-api/mc-square-api-slice";

const ErrorBoundaryContainer = ({
  children,
  bugsnagApiKey = null,
  bugsnagReleaseStage,
  revision = null,
}) => {
  const { data: squareUser = UNKNOWN_USER } = useLoadSessionQuery();

  if (!bugsnagApiKey || !revision) return children;

  useMemo(() => {
    Bugsnag.start({
      apiKey: bugsnagApiKey,
      appType: "client",
      appVersion: revision,
      plugins: [new BugsnagPluginReact()],
      releaseStage: bugsnagReleaseStage,
    });
  }, []);

  useMemo(() => {
    Bugsnag.addMetadata("squareUser", squareUser);
  }, [squareUser]);

  const Boundary = useMemo(
    () => Bugsnag.getPlugin("react").createErrorBoundary(React),
    []
  );

  return <Boundary>{children}</Boundary>;
};

ErrorBoundaryContainer.propTypes = {
  children: PropTypes.node.isRequired,
  bugsnagApiKey: PropTypes.string,
  bugsnagReleaseStage: PropTypes.string.isRequired,
  revision: PropTypes.string,
};

export default ErrorBoundaryContainer;
