import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import Bugsnag from "@bugsnag/js";
import sessionReducer from "./features/session/session-slice";
import mcSquareReducer from "./features/mc-square/mc-square-slice";
import { mcSquareApiSlice } from "./features/mc-square-api/mc-square-api-slice";
import paths from "./services/paths-service";

const logoutOnUnauthorizedResponse = () => (next) => (action) => {
  if (isRejectedWithValue(action) && action?.payload?.status === 401) {
    window.location.href = paths.logoutPath;
  }

  return next(action);
};

const reportThunkRejections = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // Notify Bugsnag of the (presumed) API error, but otherwise continue on as usual.
    Bugsnag.notify(new Error(action.payload.error), (event) => {
      const apiError = action.type.split("/")[0] === "mcSquareApi";
      const rejectedAction = { ...action };

      if (apiError) {
        // eslint-disable-next-line no-param-reassign
        event.context = `${rejectedAction.meta.arg.type} ${rejectedAction.meta.arg.endpointName} ${rejectedAction.meta.requestStatus}`;

        if (rejectedAction?.meta?.baseQueryMeta?.request) {
          rejectedAction.meta.baseQueryMeta.request = {
            bodyUsed: rejectedAction.meta.baseQueryMeta.request.bodyUsed,
            cache: rejectedAction.meta.baseQueryMeta.request.cache,
            destination: rejectedAction.meta.baseQueryMeta.request.destination,
            method: rejectedAction.meta.baseQueryMeta.request.method,
            mode: rejectedAction.meta.baseQueryMeta.request.mode,
            redirect: rejectedAction.meta.baseQueryMeta.request.redirect,
            referrer: rejectedAction.meta.baseQueryMeta.request.referrer,
            url: rejectedAction.meta.baseQueryMeta.request.url,
          };
        }
        if (rejectedAction?.meta?.baseQueryMeta?.response) {
          rejectedAction.meta.baseQueryMeta.response = {
            bodyUsed: rejectedAction.meta.baseQueryMeta.response.bodyUsed,
            ok: rejectedAction.meta.baseQueryMeta.ok,
            redirected: rejectedAction.meta.baseQueryMeta.response.redirected,
            status: rejectedAction.meta.baseQueryMeta.response.status,
            statusText: rejectedAction.meta.baseQueryMeta.response.statusText,
            type: rejectedAction.meta.baseQueryMeta.response.type,
            url: rejectedAction.meta.baseQueryMeta.response.url,
          };
        }

        // eslint-disable-next-line no-param-reassign
        event.groupingHash = event.context;
      } else {
        // eslint-disable-next-line no-param-reassign
        event.context = action.type;
      }

      event.addMetadata("action", rejectedAction);
    });
  }

  return next(action);
};

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: combineReducers({
      session: sessionReducer,
      mcSquare: mcSquareReducer,
      [mcSquareApiSlice.reducerPath]: mcSquareApiSlice.reducer,
    }),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        mcSquareApiSlice.middleware,
        logoutOnUnauthorizedResponse,
        reportThunkRejections
      ),
    devTools: true,
    preloadedState,
  });

export default setupStore({});
