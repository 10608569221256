import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticityToken: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setAuthenticityToken(draftState, action) {
      draftState.authenticityToken = action.payload;
    },
  },
});

export const { setAuthenticityToken } = sessionSlice.actions;
export default sessionSlice.reducer;
