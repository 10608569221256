import React from "react";
import { useSelector } from "react-redux";
import Logins from "../components/logins";
import { useLoadSessionQuery } from "../features/mc-square-api/mc-square-api-slice";

const LoginsContainer = () => {
  const { authenticityToken } = useSelector((state) => state.session);

  const { data: squareUser } = useLoadSessionQuery();

  return (
    <Logins squareUser={squareUser} authenticityToken={authenticityToken} />
  );
};

LoginsContainer.propTypes = {};

export default LoginsContainer;
