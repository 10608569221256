import React from "react";
import PropTypes from "prop-types";
import { Outlet, useLocation } from "react-router-dom";
import SetupFlowHeader from "./setup-flow-header";
import UserIdsDisplayContainer from "../containers/user-ids-display-container";
import { SQUARE_USER_PROP_TYPES, UNKNOWN_USER } from "../constants/proptypes";
import ConflictingStoreModalContainer from "../containers/conflicting-store-modal-container";

const SetupLayout = ({
  squareUser = UNKNOWN_USER,
  disconnect,
  disconnecting = false,
  withinPaymentReadUpdateFlow,
}) => {
  const routerLocation = useLocation();

  return (
    <div className="setup-container">
      <SetupFlowHeader
        squareUser={squareUser}
        disconnect={disconnect}
        disconnecting={disconnecting}
        withinPaymentReadUpdateFlow={withinPaymentReadUpdateFlow}
        pathname={routerLocation.pathname}
      />
      <main className="login-to-mailchimp">
        <div>
          <Outlet />
          <ConflictingStoreModalContainer />
        </div>
        <UserIdsDisplayContainer />
      </main>
    </div>
  );
};

SetupLayout.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES,
  disconnecting: PropTypes.bool,
  disconnect: PropTypes.func.isRequired,
  withinPaymentReadUpdateFlow: PropTypes.bool.isRequired,
};

export default SetupLayout;
