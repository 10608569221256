import React from "react";
import PropTypes from "prop-types";

const Accordion = ({ children }) => <ul className="accordion">{children}</ul>;

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Accordion;
