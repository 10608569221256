import React from "react";
import PropTypes from "prop-types";
import MailchimpLogo from "./mailchimp-logo";
import PlusIcon from "./icons/plus-icon";
import SquareLogo from "./square-logo";

const Header = ({ children = "" }) => (
  <header>
    <div className="icons">
      <MailchimpLogo />
      <PlusIcon className="plus" />
      <SquareLogo />
    </div>
    <div className="children">{children}</div>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
