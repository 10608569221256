import React from "react";
import PropTypes from "prop-types";

const TipIcon = ({
  alt = "Tip",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="WarningCircleIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="WarningCircleIcon">{alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 17.223c0-.741-.462-1.503-1.237-2.175a8 8 0 1110.474 0C16.462 15.72 16 16.482 16 17.223V19a3.001 3.001 0 01-2 2.83V22a1 1 0 01-1 1h-2a1 1 0 01-1-1v-.17A3.001 3.001 0 018 19v-1.777zM6 9a6 6 0 119.927 4.536c-.708.614-1.404 1.45-1.732 2.464h-4.39c-.328-1.015-1.024-1.85-1.732-2.464A5.984 5.984 0 016 9zm8 9v1a1 1 0 01-1 1h-2a1 1 0 01-1-1v-1h4z"
    />
  </svg>
);

TipIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default TipIcon;
