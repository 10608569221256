import React, { useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import {
  MAILCHIMP_MERGE_FIELD,
  MERGE_FIELD_MAPPING,
} from "../constants/proptypes";
import creatableSelectStyles from "../constants/creatable-select-styles";
import NewMergeFieldModal from "./new-merge-field-modal";

const MailchimpMergeFieldSelect = ({
  mailchimpMergeFields,
  mergeFieldMapping,
  onChange = () => {},
  isDisabled = false,
  createMailchimpMergeField = () => {},
  creatingMailchimpMergeField = false,
  invalid = false,
}) => {
  const [newMailchimpMergeField, setNewMailchimpMergeField] = useState(null);

  const mailchimpMergeFieldToOption = (mailchimpMergeField) => ({
    value: mailchimpMergeField.tag,
    label: mailchimpMergeField.name,
    ...mailchimpMergeField,
  });

  const mailchimpMergeFieldOptions = mailchimpMergeFields
    .filter(
      (mailchimpMergeField) =>
        mailchimpMergeField.tag !== "EMAIL" &&
        mailchimpMergeField.tag !== "SQUARE_PTS"
    )
    .map(mailchimpMergeFieldToOption);

  const selectedMailchimpMergeField =
    mailchimpMergeFields.find(
      (mailchimpMergeField) =>
        mailchimpMergeField.tag === mergeFieldMapping.mailchimpField
    ) || {};

  return (
    <>
      <CreatableSelect
        isClearable
        isSearchable
        isDisabled={isDisabled}
        styles={creatableSelectStyles({ invalid })}
        id="square-field-list"
        options={mailchimpMergeFieldOptions}
        value={mailchimpMergeFieldToOption(selectedMailchimpMergeField)}
        onChange={(mailchimpMergeField) => {
          // eslint-disable-next-line no-underscore-dangle
          if (mailchimpMergeField?.__isNew__) {
            const newMailchimpMergeField = {
              helpText: null,
              name: mailchimpMergeField.label.trim(),
              tag: mailchimpMergeField.label
                .trim()
                .toUpperCase()
                .replace(/[^a-zA-Z0-9]+/g, "_")
                .substr(0, 10),
              type: "text",
            };

            setNewMailchimpMergeField(newMailchimpMergeField);
            onChange(null);
          } else {
            onChange(mailchimpMergeField);
          }
        }}
      />

      <NewMergeFieldModal
        newMailchimpMergeField={newMailchimpMergeField}
        setNewMailchimpMergeField={(newMailchimpMergeField) => {
          setNewMailchimpMergeField(null);
          onChange(newMailchimpMergeField);
        }}
        cancel={() => setNewMailchimpMergeField(null)}
        createMailchimpMergeField={createMailchimpMergeField}
        creatingMailchimpMergeField={creatingMailchimpMergeField}
      />
    </>
  );
};

MailchimpMergeFieldSelect.propTypes = {
  mailchimpMergeFields: PropTypes.arrayOf(MAILCHIMP_MERGE_FIELD).isRequired,
  mergeFieldMapping: MERGE_FIELD_MAPPING.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  createMailchimpMergeField: PropTypes.func,
  creatingMailchimpMergeField: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default MailchimpMergeFieldSelect;
