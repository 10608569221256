import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SetupNavLink = ({ to, children, enabled = true }) =>
  enabled ? <NavLink to={to}>{children}</NavLink> : children;

SetupNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool,
};

export default SetupNavLink;
