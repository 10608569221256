import React from "react";
import PropTypes from "prop-types";

const SquareLogo = ({
  alt = "Square",
  className = "",
  size = 36,
  onClick = () => {},
}) => (
  <svg
    className={`logo ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    aria-labelledby="SquareLogo"
    role="img"
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="square-logo-title">{alt}</title>
    <path
      d="M36.65 0h-29.296c-4.061 0-7.354 3.292-7.354 7.354v29.296c0 4.062 3.293 7.354 7.354 7.354h29.296c4.062 0 7.354-3.292 7.354-7.354v-29.296c.001-4.062-3.291-7.354-7.354-7.354zm-.646 33.685c0 1.282-1.039 2.32-2.32 2.32h-23.359c-1.282 0-2.321-1.038-2.321-2.32v-23.36c0-1.282 1.039-2.321 2.321-2.321h23.359c1.281 0 2.32 1.039 2.32 2.321v23.36z"
      role="presentation"
    />
    <path
      d="M17.333 28.003c-.736 0-1.332-.6-1.332-1.339v-9.324c0-.739.596-1.339 1.332-1.339h9.338c.738 0 1.332.6 1.332 1.339v9.324c0 .739-.594 1.339-1.332 1.339h-9.338z"
      role="presentation"
    />
  </svg>
);

SquareLogo.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default SquareLogo;
