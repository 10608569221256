import React from "react";
import AudienceSelection from "../components/audience-selection";
import {
  useCreateListMutation,
  useGetListsQuery,
  useKickoffSyncMutation,
  useLoadSessionQuery,
  useSaveSelectedListMutation,
} from "../features/mc-square-api/mc-square-api-slice";

const AudienceSelectionContainer = () => {
  const [triggerSaveSelectedList, { isLoading: savingSelectedList }] =
    useSaveSelectedListMutation();
  const [kickoffInitialSync] = useKickoffSyncMutation();

  const {
    data: { lists, constraints: { mayCreate: canCreateList } } = {
      lists: [],
      constraints: { mayCreate: false },
    },
    isFetching: fetchingLists,
    isError: errorLoadingLists,
  } = useGetListsQuery();

  const [triggerCreateList, createListResult] = useCreateListMutation();

  const {
    data: { mailchimpList },
  } = useLoadSessionQuery();

  return (
    <AudienceSelection
      lists={lists}
      fetchingLists={fetchingLists}
      canCreateList={canCreateList}
      errorLoadingLists={errorLoadingLists}
      createList={triggerCreateList}
      createListResult={createListResult}
      selectedList={mailchimpList}
      saveSelectedList={triggerSaveSelectedList}
      savingSelectedList={savingSelectedList}
      kickoffInitialSync={kickoffInitialSync}
    />
  );
};

AudienceSelectionContainer.propTypes = {};

export default AudienceSelectionContainer;
