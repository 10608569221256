import React, { useEffect, useState } from "react";
import {
  useEnableDashboardMutation,
  useGetSyncQuery,
  useKickoffSyncMutation,
} from "../features/mc-square-api/mc-square-api-slice";
import InitialSync from "../components/initial-sync";

const InitialSyncContainer = () => {
  const [pollingInterval, setPollingInterval] = useState(2000);

  const [kickoffInitialSync] = useKickoffSyncMutation();
  useEffect(() => {
    kickoffInitialSync({ initial: true });
  }, []);

  const { data: initialSync } = useGetSyncQuery(
    { id: "initial" },
    {
      pollingInterval,
    }
  );

  const [enableDashboard] = useEnableDashboardMutation();

  if (
    (initialSync?.status === "succeeded" || initialSync?.status === "failed") &&
    pollingInterval
  ) {
    setPollingInterval(0);
  }

  return (
    <InitialSync initialSync={initialSync} enableDashboard={enableDashboard} />
  );
};

InitialSyncContainer.propTypes = {};

export default InitialSyncContainer;
