import React from "react";

import {
  useGetFeatureEnabledQuery,
  useSubmitFeedbackMutation,
} from "../features/mc-square-api/mc-square-api-slice";
import FeedbackForm from "../components/feedback-form";

const FeedbackFormContainer = () => {
  const { data: showFeedbackForm } = useGetFeatureEnabledQuery({
    feature: "show_feedback_form",
  });

  const [submitFeedback, submitFeedbackResult] = useSubmitFeedbackMutation();

  return (
    <FeedbackForm
      visible={showFeedbackForm}
      submitFeedback={submitFeedback}
      submitFeedbackResult={submitFeedbackResult}
    />
  );
};

FeedbackFormContainer.propTypes = {};

export default FeedbackFormContainer;
