import React from "react";
import PropTypes from "prop-types";

const AnalyticsIcon = ({
  alt = "Conversation",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="AnalyticsIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="AnalyticsIcon">{alt}</title>
    <path d="M6 13h2v5H6v-5zm7-3h-2v8h2v-8zm3-4h2v12h-2V6z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 22V2h22v20H1zm2-2h18V4H3v16z"
    />
  </svg>
);

AnalyticsIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default AnalyticsIcon;
