import React from "react";
import PropTypes from "prop-types";

const IconLabel = ({ icon: Icon, label, className = "" }) => (
  <div className={`icon-label ${className}`}>
    <Icon className="wink-icon" />
    {label}
  </div>
);

IconLabel.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  className: PropTypes.string,
};

export default IconLabel;
