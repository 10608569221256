const friendlyTaskName = (task) => {
  switch (task) {
    case "SyncSquareMerchant":
      return "Store";
    case "SyncSquareUser":
      return "Store Details";
    case "SyncSquareUserData":
      return "Identify Syncable Square Store Data";
    case "SyncCustomer":
    case "SyncCustomerById":
      return "Customer";
    case "SyncCustomers":
      return "Customers";
    case "SyncCustomerByLoyaltyAccountId":
      return "Loyalty Account";
    case "SyncLoyaltyPointsMergeField":
      return "Loyalty Program";
    case "SyncInventories":
    case "SyncInventory":
      return "Inventory";
    case "SyncProduct":
      return "Product";
    case "SyncProducts":
      return "Products";
    case "SyncOrder":
    case "SyncOrderById":
      return "Order";
    case "SyncOrders":
    case "ResyncOrders":
      return "Orders";
    case "SyncDeletedProducts":
      return "Cleanup Deleted Products";
    default:
      return task;
  }
};

export default friendlyTaskName;
