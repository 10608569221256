import React, { useState } from "react";
import PropTypes from "prop-types";
import TickCircleIcon from "./icons/tick-circle-icon";
import NotAllowedIcon from "./icons/not-allowed-icon";
import WarningCircleIcon from "./icons/warning-circle-icon";
import InfoCircleIcon from "./icons/info-circle-icon";
import CrossIcon from "./icons/cross-icon";

const Feedback = ({
  status,
  label = null,
  icon: Icon = null,
  children = "",
  className = null,
  dismiss = false,
}) => {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <div className={`wink-feedback wink-feedback-${status} ${className}`}>
        {Icon ? (
          <Icon className="wink-icon wink-feedback-icon" />
        ) : (
          <>
            {status === "success" && (
              <TickCircleIcon className="wink-icon wink-feedback-icon wink-icon-color-success" />
            )}
            {status === "warning" && (
              <WarningCircleIcon className="wink-icon wink-feedback-icon wink-icon-color-warning" />
            )}
            {status === "error" && (
              <NotAllowedIcon className="wink-icon wink-feedback-icon wink-icon-color-error" />
            )}
            {status === "info" && (
              <InfoCircleIcon className="wink-icon wink-feedback-icon wink-icon-color-info" />
            )}
          </>
        )}
        <div className="wink-feedback-message w-100">
          <div className="wink-text-small flex flex-row">
            <div className="w-100">
              {label && <h2 className="mt-0 wink-text-tag">{label}</h2>}
              <div className="mt-0">{children}</div>
            </div>
            {dismiss && (
              <button
                type="button"
                title="Dismiss"
                className="dismiss flex align-self-center"
                onClick={() => setVisible(false)}
                aria-label="Dismiss"
              >
                <CrossIcon className="wink-icon wink-feedback-icon wink-icon-color-info" />
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

Feedback.propTypes = {
  status: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
  label: PropTypes.string,
  icon: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  dismiss: PropTypes.bool,
};

export default Feedback;
