import React from "react";
import PropTypes from "prop-types";

const TickCircleFillIcon = ({
  alt = "Check",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="TickCircleFillIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="TickCircleFillIcon">{alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm5.707-14.293l-1.414-1.414L10 13.586l-2.293-2.293-1.414 1.414L10 16.414l7.707-7.707z"
    />
  </svg>
);

TickCircleFillIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default TickCircleFillIcon;
