import React from "react";
import SyncLogs from "../components/sync-logs";
import {
  useGetSyncQuery,
  useKickoffSyncMutation,
} from "../features/mc-square-api/mc-square-api-slice";

const SyncLogsContainer = () => {
  const { data: latestSync } = useGetSyncQuery(
    { id: "latest" },
    {
      pollingInterval: 5000,
    }
  );

  const [kickoffSync, { isLoading: kickingOffSync }] = useKickoffSyncMutation();

  return (
    <SyncLogs
      kickoffSync={kickoffSync}
      latestSync={latestSync}
      kickingOffSync={kickingOffSync}
    />
  );
};

SyncLogsContainer.propTypes = {};

export default SyncLogsContainer;
