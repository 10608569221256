export default {
  add(paths) {
    Object.assign(this, paths);
  },
  build(path, values) {
    return Object.keys(values).reduce(
      (path, valueKey) =>
        path.replace(`:${valueKey}:`, encodeURIComponent(values[valueKey])),
      path
    );
  },
};
