import React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({
  alt = "Plus",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="PlusIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="PlusIcon">{alt}</title>
    <path d="M13 4h-2v7H4v2h7v7h2v-7h7v-2h-7V4z" />
  </svg>
);

PlusIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default PlusIcon;
