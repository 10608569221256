import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SpinnerLabel from "./spinner-label";
import DataItem from "./data-item";
import TipIcon from "./icons/tip-icon";
import {
  SQUARE_USER_PROP_TYPES,
  SYNC_PROP_TYPES,
} from "../constants/proptypes";

const OverviewSummary = ({
  latestSync = null,
  loadingLatestSync,
  loadingStats,
  subscribers = undefined,
  customers = undefined,
  orders = undefined,
  products = undefined,
  squareUser,
}) => {
  const [waitingOnInitialSync, setWaitingOnInitialSync] = useState(false);

  useEffect(() => {
    if (loadingLatestSync) return;

    setWaitingOnInitialSync(!latestSync);
  }, [latestSync, loadingLatestSync]);

  const lastSyncDate =
    !loadingLatestSync &&
    latestSync &&
    new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(Date.parse(latestSync.updatedAt));

  const lastSyncTime =
    !loadingLatestSync &&
    latestSync &&
    new Intl.DateTimeFormat("default", {
      timeStyle: "short",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).format(Date.parse(latestSync.updatedAt));

  return (
    <div className="data-well">
      <p>
        This view shows all data imported from {squareUser.name} at Square to{" "}
        {squareUser.mailchimpUser.nickname}’s Mailchimp account.{" "}
        {latestSync && (
          <span>
            It was last synced {lastSyncDate} at {lastSyncTime}.
          </span>
        )}
      </p>

      {waitingOnInitialSync && (
        <SpinnerLabel label="Waiting for your initial sync to start..." />
      )}

      <div className="data-well-items">
        <DataItem
          label="Subscribers"
          value={subscribers}
          tooltip="Customers synced who signed up for your mailing list or opted in to receive emails."
          loading={loadingStats}
        />
        <DataItem
          label="Customers"
          value={customers}
          tooltip="Total number of customers synced including non-subscribers."
          loading={loadingStats}
        />
        <DataItem
          label="Orders"
          value={orders}
          tooltip="Total number of orders synced to Mailchimp (doesn't account for subscriptions, refunds, or chargebacks)."
          loading={loadingStats}
        />
        <DataItem
          label="Products"
          value={products}
          tooltip="The number of products synced to Mailchimp based on data from your store."
          loading={loadingStats}
        />
      </div>

      <div className="data-well-footer">
        <p className="wink-form-label-secondary">
          <TipIcon className="tip-icon" /> Increase the number of subscribers by{" "}
          <a
            href="https://squareup.com/help/us/en/article/5976-get-started-with-email-collection-tools"
            target="_blank"
            rel="noreferrer"
          >
            turning on email collection
          </a>{" "}
          with Square.
        </p>
        {(latestSync?.status === "in_progress" ||
          latestSync?.status === "retrying") && (
          <SpinnerLabel label="Syncing..." />
        )}
      </div>
    </div>
  );
};

OverviewSummary.propTypes = {
  latestSync: SYNC_PROP_TYPES,
  loadingLatestSync: PropTypes.bool.isRequired,
  loadingStats: PropTypes.bool.isRequired,
  subscribers: PropTypes.number,
  customers: PropTypes.number,
  orders: PropTypes.number,
  products: PropTypes.number,
  squareUser: SQUARE_USER_PROP_TYPES.isRequired,
};

export default OverviewSummary;
