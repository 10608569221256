import React from "react";
import PropTypes from "prop-types";
import TickCircleFillIcon from "./icons/tick-circle-fill-icon";
import MenuUpIcon from "./icons/menu-up-icon";
import MenuDownIcon from "./icons/menu-down-icon";

const AccordionItem = ({ active, activate, children, label, complete }) => (
  <li
    className={`accordion-item ${
      active ? "accordion-item-active" : "accordion-item-inactive"
    }`}
  >
    <button type="button" className="wink-button" onClick={activate}>
      <h2>{label}</h2>
      <TickCircleFillIcon
        className={complete ? "complete" : "incomplete"}
        size={32}
        alt={complete ? "Completed" : "Not completed"}
      />
      <MenuUpIcon className="menu-up" />
      <MenuDownIcon className="menu-down" />
    </button>
    <div className="accordion-content">{children}</div>
  </li>
);

AccordionItem.propTypes = {
  active: PropTypes.bool.isRequired,
  activate: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  complete: PropTypes.bool.isRequired,
};

export default AccordionItem;
