import React from "react";
import ReactGA from "react-ga4";

const trackVisitDashboard = () =>
  ReactGA.event({
    category: "dashboard",
    action: "click",
    label: "visit dashboard",
  });

const OverviewHeader = () => (
  <header className="logged-in-header">
    <h3>Sync Overview</h3>
    <a
      href="https://admin.mailchimp.com/integrations/manage/?name=square"
      target="_blank"
      rel="noreferrer"
      onClick={trackVisitDashboard}
    >
      Visit Mailchimp Dashboard
    </a>
  </header>
);

OverviewHeader.propTypes = {};

export default OverviewHeader;
