import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import SyncStatus from "./sync-status";
import Feedback from "./feedback";
import { SYNC_PROP_TYPES } from "../constants/proptypes";
import SpinnerLabel from "./spinner-label";

const trackGoToDashboard = () =>
  ReactGA.event({
    category: "sync in progress",
    action: "click",
    label: "go to dashboard",
  });

const InitialSync = ({ initialSync = null, enableDashboard }) => (
  <>
    {initialSync?.status !== "succeeded" ? (
      <>
        <h1>Syncing your data...</h1>

        <p>
          Your Square catalog items, customer profiles, orders, and other
          related data are syncing to Mailchimp. This process might take a
          while, so feel free to explore the app dashboard while you wait.
        </p>
      </>
    ) : (
      <div className="sync-celebration">
        <img
          src="/images/Celebration-whitefill.png"
          alt="Example of email automation sent to first-time customers"
        />
        <h1>You connected your accounts. Nice!</h1>
        <p>
          <button
            type="button"
            className=" link"
            onClick={() => enableDashboard()}
          >
            Visit the app dashboard
          </button>{" "}
          to see your imported data and how to use it in Mailchimp.
        </p>
      </div>
    )}

    <div className="overall-status">
      {initialSync?.status === "failed" && (
        <Feedback label="Sync Failed" status="error">
          There was an unexpected problem completing your initial sync. Please
          see your sync logs for more information.
        </Feedback>
      )}
    </div>
    {initialSync ? (
      <SyncStatus sync={initialSync} />
    ) : (
      <SpinnerLabel label="Waiting for your initial sync to start..." />
    )}
    <form>
      <div className="wink-form-field-container action-buttons">
        <button
          type="button"
          className="wink-button wink-button-primary"
          onClick={async () => {
            enableDashboard();
            trackGoToDashboard();
          }}
        >
          Go to dashboard
        </button>
      </div>
    </form>
  </>
);

InitialSync.propTypes = {
  initialSync: SYNC_PROP_TYPES,
  enableDashboard: PropTypes.func.isRequired,
};

export default InitialSync;
