import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import SquareLogo from "./square-logo";
import TickCircleFillIcon from "./icons/tick-circle-fill-icon";
import MailchimpLogo from "./mailchimp-logo";
import { SQUARE_USER_PROP_TYPES, UNKNOWN_USER } from "../constants/proptypes";
import Feedback from "./feedback";

const trackLoginWithMailchimp = () =>
  ReactGA.event({
    category: "login",
    action: "click",
    label: "login with Mailchimp",
  });

const trackLoginWithSquare = () =>
  ReactGA.event({
    category: "login",
    action: "click",
    label: "login with Square",
  });

const UpdateLogins = ({ squareUser = UNKNOWN_USER, authenticityToken }) => {
  const { mailchimpUser } = squareUser;

  const url = new URL(document.location.href);

  const squareAccessDenied =
    url.pathname === "/auth/failure" &&
    url.searchParams.get("message") === "access_denied" &&
    url.searchParams.get("strategy") === "square";

  return (
    <>
      <h1>Update your Square integration</h1>

      <p>
        Square recently made some updates that changed how the integration syncs
        your order data.
      </p>

      <p>
        Log in and authorize your Mailchimp and Square accounts to get the
        update that improves syncing.
      </p>

      {squareUser.mismatchedMailchimpUser && (
        <Feedback label="Square Log In Canceled" status="error">
          <p>
            The Square account you logged into is already connected to a
            different Mailchimp user account. A Square account can only be
            connected to a single Mailchimp account. To use this Square account
            you will need to first log in with the Mailchimp account that is
            already associated to it and then disconnect from within settings.
          </p>

          <p>
            If you do not know which Mailchimp account is already associated
            with this Square account you can reset the integration by
            disconnecting the Mailchimp app integration from within Square.
          </p>
        </Feedback>
      )}

      {squareAccessDenied && (
        <Feedback label="Square authorization required" status="error">
          To complete the update, you must log into your Square account and
          grant the requested permissions.
        </Feedback>
      )}

      <div className="login-status">
        <div className="service-name">
          <MailchimpLogo />
          <div className="wink-h3">Mailchimp</div>
        </div>

        <div className="auth-status">
          {mailchimpUser?.authorized ? (
            <>
              <div className="icon-label">
                <TickCircleFillIcon className="" />
                {mailchimpUser.firstName} {mailchimpUser.lastName} Authorized
              </div>

              <form action="/auth/mailchimp" method="post">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={authenticityToken}
                />
                <button
                  type="submit"
                  className="wink-button wink-button-secondary"
                >
                  Log in with a different Mailchimp account
                </button>
              </form>
            </>
          ) : (
            <form action="/auth/mailchimp" method="post">
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
              />
              <button
                type="submit"
                className="wink-button wink-button-primary"
                onClick={trackLoginWithMailchimp}
              >
                Log in with Mailchimp
              </button>
            </form>
          )}
        </div>
      </div>

      <div className="login-status">
        <div className="service-name">
          <SquareLogo />
          <div className="wink-h3">Square</div>
        </div>

        <div className="auth-status">
          <form action="/auth/square" method="post">
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <button
              type="submit"
              className="wink-button wink-button-primary"
              disabled={!mailchimpUser?.authorized}
              onClick={trackLoginWithSquare}
            >
              Log in with Square
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

UpdateLogins.propTypes = {
  squareUser: SQUARE_USER_PROP_TYPES,
  authenticityToken: PropTypes.string.isRequired,
};

export default UpdateLogins;
