import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import creatableSelectStyles from "constants/creatable-select-styles";
import { useForm } from "react-hook-form";
import {
  MAILCHIMP_LIST_PROP_TYPES,
  MUTATION_RESULT_PROP_TYPES,
} from "../constants/proptypes";
import NotAllowedIcon from "./icons/not-allowed-icon";
import Modal from "./modal";
import SpinnerLabel from "./spinner-label";
import TickCircleIcon from "./icons/tick-circle-icon";
import remoteErrors from "../services/remote-errors";

const toSelectObject = (list) => ({
  value: list?.id,
  label: list?.name,
});

const MailchimpList = ({
  lists,
  fetchingLists,
  canCreateList,
  selectedList = null,
  errorLoadingLists,
  createList,
  createListResult,
  onSelectedListChange,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [newListName, setNewListName] = useState("");

  const creatingNewList = createListResult.isLoading;

  const Selector = useMemo(
    () => (canCreateList ? CreatableSelect : Select),
    [canCreateList]
  );

  const selectedListObject = useMemo(
    () =>
      toSelectObject(
        lists.find((list) => list?.id === selectedList?.remoteListId)
      ),
    [lists, selectedList]
  );

  const fieldHasErrors = (field) =>
    errors[field] || remoteErrors(createListResult, field);

  const options = lists.map((list) => ({
    value: list.id,
    label: list.name,
  }));

  return (
    <>
      {errorLoadingLists && (
        <div className="wink-feedback wink-feedback-error">
          <NotAllowedIcon className="wink-feedback-icon" />
          <div className="wink-feedback-message">
            <h2 className="wink-text-tag">
              We couldn&apos;t load your Mailchimp audiences.
            </h2>
            <p className="wink-text-small">
              There was an unexpected error loading your mailchimp audiences.
            </p>
          </div>
        </div>
      )}

      {createListResult.status === "fulfilled" &&
        createListResult.isSuccess && (
          <div className="wink-feedback wink-feedback-success wink-feedback-icon-success">
            <TickCircleIcon className="wink-feedback-icon" />
            <div className="wink-feedback-message">
              <h2 className="wink-text-tag">
                Your new audience has been created!
              </h2>
            </div>
          </div>
        )}

      <div className="wink-form-field-container">
        <div className="wink-form-label-container">
          <label
            className="wink-form-label"
            htmlFor="mailchimp-list"
            id="mailchimp-list-label"
          >
            Choose an audience from Mailchimp
          </label>
          <span
            aria-describedby="mailchimp-list-label"
            className="wink-form-label-secondary"
          >
            Required
          </span>
        </div>
        {fetchingLists && <SpinnerLabel label="Loading your audiences..." />}
        {!fetchingLists && (
          <>
            <Selector
              isClearable
              isSearchable={canCreateList}
              styles={creatableSelectStyles()}
              onChange={(selectedList) => {
                if (selectedList === null) {
                  onSelectedListChange(null);
                  // eslint-disable-next-line no-underscore-dangle
                } else if (selectedList?.__isNew__) {
                  setNewListName(selectedList.label);
                  setShowConfigDialog(true);
                } else {
                  onSelectedListChange({
                    remoteListId: selectedList.value,
                    listName: selectedList.label,
                  });
                }
              }}
              value={selectedListObject}
              isDisabled={fetchingLists || creatingNewList}
              options={options}
              id="mailchimp-list"
            />
            {canCreateList && (
              <p
                aria-describedby="mailchimp-list-label"
                className="wink-form-description"
              >
                We recommend using a single audience to organize contacts and
                optimize your marketing in Mailchimp. Learn more about{" "}
                <a
                  href="https://mailchimp.com/resources/one-audience-organize-contacts-to-optimize-marketing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  using multiple audiences.
                </a>
              </p>
            )}
          </>
        )}
      </div>

      <Modal isOpen={showConfigDialog} close={() => setShowConfigDialog(false)}>
        <h3>Settings for &quot;{newListName}&quot;</h3>

        <form
          onSubmit={handleSubmit(async (values) => {
            const result = await createList({ ...values, newListName });

            if (!result.error) {
              onSelectedListChange({
                remoteListId: result.data.id,
                listName: result.data.name,
              });
              setShowConfigDialog(false);
            }
          })}
        >
          <fieldset>
            <div className="wink-form-field-container">
              <div className="wink-form-label-container">
                <label
                  className="wink-form-label"
                  htmlFor="from-name"
                  id="from-name-label"
                >
                  From Name
                </label>
                <span
                  aria-describedby="from-name-label"
                  className="wink-form-label-secondary"
                >
                  Required
                </span>
              </div>
              <input
                className={`wink-form-field ${
                  fieldHasErrors("fromName") ? "wink-form-field-error" : ""
                }`}
                maxLength="100"
                {...register("fromName", {
                  required: true,
                })}
              />
              <p
                aria-describedby="from-name-label"
                className="wink-form-description wink-form-description-error"
              >
                {errors.fromName?.type === "required" &&
                  "From Name is a required field."}
              </p>
              <p
                aria-describedby="from-name-label"
                className="wink-form-description wink-form-description-error"
              >
                {remoteErrors(createListResult, "fromName")}
              </p>
              <p aria-describedby="from-name-label">
                This is the name your emails will come from. Use something your
                subscribers will instantly recognize, like your company name.
              </p>
            </div>
            <div className="wink-form-field-container">
              <div className="wink-form-label-container">
                <label
                  className="wink-form-label"
                  htmlFor="permission-reminder"
                  id="permission-reminder-label"
                >
                  Permission Reminder
                </label>
                <span
                  aria-describedby="permission-reminder-label"
                  className="wink-form-label-secondary"
                >
                  Required
                </span>
              </div>
              <input
                className={`wink-form-field ${
                  fieldHasErrors("permissionReminder")
                    ? "wink-form-field-error"
                    : ""
                }`}
                {...register("permissionReminder", {
                  required: true,
                })}
              />
              <p
                aria-describedby="permission-reminder-label"
                className="wink-form-description wink-form-description-error"
              >
                {errors.permissionReminder?.type === "required" &&
                  "Permission Reminder is a required field."}
              </p>

              <p
                aria-describedby="from-name-label"
                className="wink-form-description wink-form-description-error"
              >
                {remoteErrors(createListResult, "permissionReminder")}
              </p>
              <p aria-describedby="permission-reminder-label">
                Remind recipients how they signed up to your audience. This will
                appear in the footer of emails sent to the audience and can be
                changed on the Mailchimp website. For example, &quot;We send
                special offers to customers who purchase from our website.&quot;
              </p>
            </div>
            <div className="wink-form-control-container">
              <label className="wink-form-control-label" id="double-opt-in">
                <input
                  type="checkbox"
                  {...register("enableDoubleOptin")}
                  className="wink-form-control wink-form-control-checkbox"
                />
                Enable double opt-in
              </label>
              <p
                aria-describedby="double-opt-in"
                className="wink-form-description"
              >
                Sent contacts an opt-in confirmation email when they subscribe
                to your audience.
              </p>
            </div>
          </fieldset>
          <div className="wink-form-field-container action-buttons">
            <button
              type="button"
              className="wink-button wink-button-secondary"
              onClick={() => setShowConfigDialog(false)}
            >
              Cancel
            </button>
            <button type="submit" className="wink-button wink-button-primary">
              {creatingNewList ? (
                <SpinnerLabel label="Create audience" />
              ) : (
                <>Create audience</>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

MailchimpList.propTypes = {
  lists: PropTypes.arrayOf(MAILCHIMP_LIST_PROP_TYPES).isRequired,
  fetchingLists: PropTypes.bool.isRequired,
  canCreateList: PropTypes.bool.isRequired,
  selectedList: PropTypes.exact({
    listName: PropTypes.string.isRequired,
    remoteListId: PropTypes.string.isRequired,
  }),
  errorLoadingLists: PropTypes.bool.isRequired,
  createList: PropTypes.func.isRequired,
  createListResult: MUTATION_RESULT_PROP_TYPES.isRequired,
  onSelectedListChange: PropTypes.func.isRequired,
};

export default MailchimpList;
