import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Feedback from "./feedback";
import { SYNC_ERROR_PROP_TYPE } from "../constants/proptypes";
import friendlyTaskName from "../services/worker-translator";
import SpinnerLabel from "./spinner-label";

const statusClassification = (status) => {
  switch (status) {
    case "resolved":
      return "success";
    case "ignored":
    case "retrying":
      return "warning";
    case "failed":
      return "error";
    default:
      return "";
  }
};

const SyncError = ({ syncError = null, retrySyncError, setSelectedSyncId }) => {
  const [waitingOnRetryToStart, setWaitingOnRetryToStart] = useState(false);

  useEffect(() => {
    setWaitingOnRetryToStart(
      syncError.manualRetrySyncBatchId && !syncError.manualRetrySyncId
    );
  }, [syncError]);

  const syncErrorStatus = () => {
    if (syncError.resolved) {
      return `This item succeeded after ${syncError.occurrences + 1} attempts.`;
    }

    if (syncError.ignored) {
      return "This item has been skipped.";
    }

    if (syncError.manualRetrySyncId) {
      return "This item has been manually retried.";
    }

    if (syncError.status !== "failed") {
      return `Attempted ${syncError.occurrences} of ${
        syncError.occurrences + syncError.retriesRemaining + 1
      } times.`;
    }

    return `This item has failed to sync.`;
  };

  const initiateRetry = () => {
    setWaitingOnRetryToStart(true);
    retrySyncError(syncError.id);
  };

  return (
    <Feedback
      key={syncError.id}
      label={syncError.friendlyMessage}
      className="problem-summary"
      status={statusClassification(syncError.status)}
    >
      <table className="table-list">
        <tbody>
          <tr>
            <th>Sync Task</th>
            <td>{friendlyTaskName(syncError.worker)}</td>
          </tr>
          {syncError.metadata.map(({ name, value }) => (
            <tr key={name}>
              <th>{name}</th>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="sync-error-footer">
        <div>
          <div>{syncErrorStatus()}</div>
          {syncError.status === "failed" && (
            <div>
              {syncError.manualRetrySyncId ? (
                <div>
                  <button
                    type="button"
                    className="wink-button wink-button-secondary"
                    onClick={() =>
                      setSelectedSyncId(syncError.manualRetrySyncId)
                    }
                  >
                    View retry logs.
                  </button>
                </div>
              ) : (
                <div>
                  {waitingOnRetryToStart ? (
                    <SpinnerLabel label="Waiting for retry to start..." />
                  ) : (
                    <button
                      type="button"
                      className="wink-button wink-button-secondary"
                      onClick={() => initiateRetry()}
                    >
                      Manually Retry This Item
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="sync-error-id">ID {syncError.id}</div>
      </div>
    </Feedback>
  );
};

SyncError.propTypes = {
  syncError: SYNC_ERROR_PROP_TYPE,
  retrySyncError: PropTypes.func.isRequired,
  setSelectedSyncId: PropTypes.func.isRequired,
};

export default SyncError;
