import React from "react";
import { useNavigate } from "react-router-dom";

const MailchimpAccountCreationSuccessContainer = () => {
  const navigate = useNavigate();

  return (
    <div className="mailchimp-account-creation-container">
      <div className="success-container">
        <div className="text-container">
          <h2>All set up!</h2>
          <h3>We’re taking you back to log in with Square.</h3>
          <p>
            Not redirected after a few seconds?{" "}
            <button
              type="button"
              className="link"
              onClick={() => {
                navigate("/");
              }}
            >
              Continue Setup
            </button>
            .
          </p>
        </div>
      </div>
      <meta httpEquiv="refresh" content="3; url=#/" />
    </div>
  );
};

MailchimpAccountCreationSuccessContainer.propTypes = {};

export default MailchimpAccountCreationSuccessContainer;
