import React from "react";

const Support = () => (
  <div>
    <h1>FAQs</h1>

    <dt className="wink-h2">How do I access Square products in Mailchimp?</dt>
    <dd>
      <p>
        You can add a{" "}
        <a
          href="https://mailchimp.com/help/use-product-content-blocks/"
          target="_blank"
          rel="noreferrer"
        >
          Product content block
        </a>{" "}
        to your email to quickly promote best-selling products or special
        offers. Drag and drop the block into your layout, and choose the
        products you want to share. Then, check out revenue results in your
        report.
      </p>
    </dd>
    <dt className="wink-h2">How is Subscription Status synced?</dt>
    <dd>
      <p>
        All customers will be synced over with an opt-in status (Subscribed or
        Unsubscribed) based on the customer&apos;s opt-in status that comes from
        Square.
      </p>
    </dd>
    <dt className="wink-h2">Is Double Opt-in Supported?</dt>
    <dd>
      <p>
        Yes! You can choose to{" "}
        <a
          href="https://mailchimp.com/help/about-double-opt-in/"
          target="_blank"
          rel="noreferrer"
        >
          enable double opt-in
        </a>{" "}
        when you create an Audience in Mailchimp.
      </p>
    </dd>
    <dt className="wink-h2">What Product types are supported?</dt>
    <dd>
      <p>
        Square calls products &quot;items,&quot; and offers a number of item
        types: Physical, Digital, Donation, Membership, Event, Service, and
        Prepared Food and Beverage.{" "}
        <a
          href="https://squareup.com/help/us/en/article/6879-add-items-and-categories-to-your-square-online-store"
          target="_blank"
          rel="noreferrer"
        >
          Learn more about Square Item types here.
        </a>
      </p>

      <p>
        All of the above Item Types will be syncing to your Mailchimp account as
        Products, and all Orders that include these Item Types will be synced
        too.
      </p>
    </dd>
    <dt className="wink-h2">
      How does the integration handle Refunds from Square
    </dt>
    <dd>
      <p>Refunds will not be syncing to Mailchimp at this time.</p>
    </dd>

    <dt className="wink-h2">
      How does the integration handle Subscriptions from Square?
    </dt>
    <dd>
      <p>
        Orders related to{" "}
        <a
          href="https://squareup.com/help/us/en/article/7627-get-started-with-subscriptions-in-dashboard"
          target="_blank"
          rel="noreferrer"
        >
          Subscriptions
        </a>{" "}
        will not be syncing to Mailchimp. However,{" "}
        <a
          href="https://mailchimp.com/help/getting-started-tags/#About_tags"
          target="_blank"
          rel="noreferrer"
        >
          Mailchimp Tags
        </a>{" "}
        will be added to your Mailchimp Contacts when they sign up for one of
        your Square Subscriptions. The Tags will look something like this:
        &quot;Name of your subscription plan (state of the contact’s
        subscription)&quot;; for example: &quot;Potted Planner Square
        Subscription (active)&quot;.
      </p>
    </dd>

    <dt className="wink-h2">
      Am I able to turn off transactional emails at Square?
    </dt>
    <dd>
      <p>
        Currently there is not a way to turn off transactional emails that are
        sent from Square. However, you can{" "}
        <a
          href="https://squareup.com/help/us/en/article/6889-customize-store-emails-with-square-online-store"
          target="_blank"
          rel="noreferrer"
        >
          edit and customize them from within your Square account
        </a>
        .
      </p>
    </dd>
    <dt className="wink-h2">
      Can Mailchimp send Abandoned Cart emails to my Square customers?
    </dt>
    <dd>
      <p>
        This feature is not yet supported, but we are working with Square to add
        abandoned cart syncing soon. You may use{" "}
        <a
          href="https://squareup.com/help/us/en/article/6958-abandoned-cart-emails"
          target="_blank"
          rel="noreferrer"
        >
          Square&apos;s Abandoned Cart emails
        </a>{" "}
        in the meantime.
      </p>
    </dd>
    <dt className="wink-h2">Are Promo Codes supported?</dt>
    <dd>
      <p>Promo Codes will not be syncing to Mailchimp at this time.</p>
    </dd>
    <dt className="wink-h2">How far back does my data sync to Mailchimp?</dt>
    <dd>
      <p>All historical information will be synced.</p>
    </dd>
    <dt className="wink-h2">
      When I remove a customer from my Square list, will the associated contact
      also be removed in Mailchimp?
    </dt>
    <dd>
      <p>
        No, the contact will still be in your Mailchimp audience. If you know
        this customer doesn&apos;t want to receive marketing messaging from you,
        or if you want to remove them from your audience, you may{" "}
        <a
          href="https://mailchimp.com/help/archive-unarchive-contacts/"
          target="_blank"
          rel="noreferrer"
        >
          archive the contact
        </a>
        .
      </p>
    </dd>
    <dt className="wink-h2">
      When I merge two customers in Square, what will that look like in
      Mailchimp?
    </dt>
    <dd>
      <p>
        A third contact will be created in Mailchimp representing the new,
        &quot;merged&quot; Square customer. The original two contacts will still
        be present in your Mailchimp audience, and you may{" "}
        <a
          href="https://mailchimp.com/help/archive-unarchive-contacts/"
          target="_blank"
          rel="noreferrer"
        >
          archive contacts
        </a>{" "}
        if you no longer want to send Mailchimp campaigns to them.
      </p>
    </dd>
  </div>
);

Support.propTypes = {};

export default Support;
