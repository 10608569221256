import React from "react";
import PropTypes from "prop-types";

const TickCircleIcon = ({
  alt = "Check",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="TickCircleIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="TickCircleIcon">{alt}</title>
    <path d="M10 16.414l7.707-7.707-1.414-1.414L10 13.586l-2.293-2.293-1.414 1.414L10 16.414z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zm-2 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

TickCircleIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default TickCircleIcon;
