import React from "react";
import DisconnectContainer from "../containers/disconnect-container";
import AudienceSelectionContainer from "../containers/audience-selection-container";
import MergeFieldMappingsSetupContainer from "../containers/merge-field-mappings-setup-container";

const Settings = () => (
  <div className="constrained-container">
    <h1>Settings</h1>

    <AudienceSelectionContainer />

    <MergeFieldMappingsSetupContainer editMode />

    <DisconnectContainer />
  </div>
);

Settings.propTypes = {};

export default Settings;
