import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import HamburgerIcon from "./icons/hamburger-icon";
import OverviewHeader from "./overview-header";
import Header from "./header";
import IconLabel from "./icon-label";
import HomeIcon from "./icons/home-icon";
import SettingsIcon from "./icons/settings-icon";
import InfoCircleIcon from "./icons/info-circle-icon";
import ConversationIcon from "./icons/conversation-icon";
import paths from "../services/paths-service";
import ExitIcon from "./icons/exit-icon";
import UserIdsDisplayContainer from "../containers/user-ids-display-container";
import { useLoadSessionQuery } from "../features/mc-square-api/mc-square-api-slice";

const DashboardLayout = () => {
  const { refetch: refetchSession } = useLoadSessionQuery();

  const [showMenu, setShowMenu] = useState(false);
  const routerLocation = useLocation();

  useEffect(() => {
    refetchSession();
  }, [routerLocation.pathname]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <div className="dashboard-sidebar wink-h4 wink-nomargin">
          Square by Mailchimp
        </div>

        <HamburgerIcon
          className="hamburger"
          onClick={() => setShowMenu(!showMenu)}
        />

        {routerLocation.pathname === "/" ||
        routerLocation.pathname === "/all-set" ? (
          <OverviewHeader />
        ) : (
          <Header>
            <div className="small-label">Square by Mailchimp</div>
          </Header>
        )}
      </div>
      <div className="dashboard-content">
        <nav className={`dashboard-sidebar ${showMenu ? "show-menu" : ""}`}>
          <ul>
            <li>
              <NavLink to="/" className="wink-button">
                <IconLabel label="Dashboard" icon={HomeIcon} />
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings" className="wink-button">
                <IconLabel label="Settings" icon={SettingsIcon} />
              </NavLink>
            </li>
            <li>
              <NavLink to="/sync-logs" className="wink-button">
                <IconLabel label="Sync Logs" icon={InfoCircleIcon} />
              </NavLink>
            </li>
            <li>
              <NavLink to="/support" className="wink-button">
                <IconLabel label="Support" icon={ConversationIcon} />
              </NavLink>
            </li>
            <li>
              <a
                href={paths.logoutPath}
                className="wink-button wink-button-tertiary"
              >
                <IconLabel label="Log Out" icon={ExitIcon} />
              </a>
            </li>
          </ul>
        </nav>
        <main className="main-content">
          <Outlet />
          <UserIdsDisplayContainer />
        </main>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {};

export default DashboardLayout;
