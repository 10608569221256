import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useGetSyncsQuery } from "../features/mc-square-api/mc-square-api-slice";
import SyncLogsTable from "../components/sync-logs-table";
import { setSelectedSyncId } from "../features/mc-square/mc-square-slice";

const SyncLogsTableContainer = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { data: { data: syncs, pagination } = {}, isLoading: gettingSyncs } =
    useGetSyncsQuery(
      { page },
      {
        pollingInterval: 5000,
      }
    );

  return (
    <SyncLogsTable
      syncs={syncs}
      pagination={pagination}
      gettingSyncs={gettingSyncs}
      setPage={setPage}
      setSelectedSyncId={(syncId) => dispatch(setSelectedSyncId(syncId))}
    />
  );
};

SyncLogsTableContainer.propTypes = {};

export default SyncLogsTableContainer;
