import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import Accordion from "./accordion";
import AccordionItem from "./accordion-item";
import ProgressCircle from "./progress-circle";

const trackAccordionChange = (from, to) => {
  if (from === to) return;

  if (from) {
    ReactGA.event({
      category: "dashboard",
      action: "close accordion",
      label: `close reco ${from}`,
    });
  }
  if (to) {
    ReactGA.event({
      category: "dashboard",
      action: "open accordion",
      label: `open reco ${to}`,
    });
  }
};

const trackCallToActionClick = (label) =>
  ReactGA.event({
    category: "dashboard",
    action: "click",
    label,
  });

const RecommendationsChecklist = ({
  completedRecommendations = [],
  recordRecommendationCompletion,
}) => {
  const CUSTOMIZE_WORKFLOW = "customizeWorkflow";
  const BUILD_RELATIONSHIPS = "buildRelationships";
  const SEGMENT_CUSTOMERS = "segmentCustomers";

  const recommendationIsCompleted = (step) =>
    completedRecommendations.includes(step);

  const recommendations = [
    CUSTOMIZE_WORKFLOW,
    BUILD_RELATIONSHIPS,
    SEGMENT_CUSTOMERS,
  ];

  const incompleteRecommendations = recommendations.filter(
    (recommendation) => !recommendationIsCompleted(recommendation)
  );

  const [activeRecommendation, setActiveRecommendation] = useState(
    incompleteRecommendations[0]
  );

  const [showRecommendations, setShowRecommendations] = useState(true);

  useEffect(() => {
    setShowRecommendations(!!incompleteRecommendations.length);
  }, [incompleteRecommendations.length]);

  const completeRecommendation = (recommendation) => {
    const nextIncompleteRecommendation = incompleteRecommendations.filter(
      (incompleteRecommendation) => incompleteRecommendation !== recommendation
    )[0];
    setActiveRecommendation(nextIncompleteRecommendation);
    recordRecommendationCompletion(recommendation);
    trackAccordionChange(recommendation, nextIncompleteRecommendation);
  };

  return showRecommendations ? (
    <>
      <div className="progress-header">
        <h3>Here’s what we recommend you do next...</h3>
        <ProgressCircle
          completed={completedRecommendations.length}
          total={recommendations.length}
        />
      </div>

      <Accordion>
        <AccordionItem
          active={activeRecommendation === CUSTOMIZE_WORKFLOW}
          activate={() => {
            setActiveRecommendation(CUSTOMIZE_WORKFLOW);
            trackAccordionChange(activeRecommendation, CUSTOMIZE_WORKFLOW);
          }}
          label="Customize workflows that convert prospects to sales"
          complete={recommendationIsCompleted(CUSTOMIZE_WORKFLOW)}
        >
          <div className="recommendation-with-image">
            <div className="recommendation-content">
              <h2>Win repeat business</h2>
              <p>
                Encourage first-time shoppers to become loyal returning
                customers with post-sale promotions.
              </p>
              <p>
                On average, see up to 4x more orders with pre-built email series
                triggered by customer behaviors.
              </p>
              <div className="action-buttons">
                <a
                  href="https://us7.admin.mailchimp.com/customer-journey/explore/prebuilt?id=special_offer_after_first_purchase"
                  target="_blank"
                  rel="noreferrer"
                  className="wink-button wink-button-primary"
                  onClick={() => {
                    completeRecommendation(CUSTOMIZE_WORKFLOW);
                    trackCallToActionClick("reco 1 cta primary");
                  }}
                >
                  Create Repeat Customers flow
                </a>
                <a
                  href="https://us7.admin.mailchimp.com/customer-journey/"
                  target="_blank"
                  rel="noreferrer"
                  className="wink-button wink-button-tertiary"
                  onClick={() => {
                    completeRecommendation(CUSTOMIZE_WORKFLOW);
                    trackCallToActionClick("reco 1 cta secondary");
                  }}
                >
                  Browse all automations
                </a>
              </div>
            </div>
            <img
              srcSet="/images/aui-checklist-1-1x.jpg, /images/aui-checklist-1-2x.jpg 2x, /images/aui-checklist-1-3x.jpg 3x"
              src="/images/aui-checklist-1-3x.jpg"
              alt="Example of email automation sent to first-time customers"
            />
          </div>
        </AccordionItem>
        <AccordionItem
          active={activeRecommendation === BUILD_RELATIONSHIPS}
          activate={() => {
            setActiveRecommendation(BUILD_RELATIONSHIPS);
            trackAccordionChange(activeRecommendation, BUILD_RELATIONSHIPS);
          }}
          label="Build customer relationships with email campaigns"
          complete={recommendationIsCompleted(BUILD_RELATIONSHIPS)}
        >
          <div className="recommendation-with-image">
            <div className="recommendation-content">
              <h2>Re-engage lapsed customers</h2>
              <p>
                Show inactive subscribers what they’re missing out on. Send a
                one-time email to a targeted group of contacts and promote new
                products or offer a deal too good to resist.
              </p>
              <p>Send emails right away or schedule them for later.</p>
              <div className="action-buttons">
                <a
                  href="https://mailchimp.com/marketplace/templates/win-them-back/?utm_source=email&utm_medium=email&utm_campaign=mc_aoc__app-marketplace-nl2__eng_wld_en_202202&utm_content=text_body__app-marketplace-nl2_"
                  target="_blank"
                  rel="noreferrer"
                  className="wink-button wink-button-primary"
                  onClick={() => {
                    completeRecommendation(BUILD_RELATIONSHIPS);
                    trackCallToActionClick("reco 2 cta primary");
                  }}
                >
                  Create Win Them Back campaign
                </a>
                <a
                  href="https://us7.admin.mailchimp.com/campaigns/#t:campaigns-list"
                  target="_blank"
                  rel="noreferrer"
                  className="wink-button wink-button-tertiary"
                  onClick={() => {
                    completeRecommendation(BUILD_RELATIONSHIPS);
                    trackCallToActionClick("reco 2 cta secondary");
                  }}
                >
                  Explore all campaigns
                </a>
              </div>
            </div>
            <img
              srcSet="/images/aui-checklist-2-1x.jpg, /images/aui-checklist-2-2x.jpg 2x, /images/aui-checklist-2-3x.jpg 3x"
              src="/images/aui-checklist-2-3x.jpg"
              alt="Example of Win Them Back campaign email"
            />
          </div>
        </AccordionItem>
        <AccordionItem
          active={activeRecommendation === SEGMENT_CUSTOMERS}
          activate={() => {
            setActiveRecommendation(SEGMENT_CUSTOMERS);
            trackAccordionChange(activeRecommendation, SEGMENT_CUSTOMERS);
          }}
          label="Segment your newly imported customers by engagement"
          complete={recommendationIsCompleted(SEGMENT_CUSTOMERS)}
        >
          <div className="recommendation-with-image">
            <div className="recommendation-content">
              <h2>Find your most valuable contacts</h2>
              <p>
                Segmentation tools help you filter your Square contact data to
                target the right campaigns to the right customers. Reach your
                segmented audiences in just a few clicks with special promotions
                or personalized messaging.
              </p>
              <div className="action-buttons">
                <a
                  href="https://us7.admin.mailchimp.com/lists/segments/"
                  target="_blank"
                  rel="noreferrer"
                  className="wink-button wink-button-primary "
                  onClick={() => {
                    completeRecommendation(SEGMENT_CUSTOMERS);
                    trackCallToActionClick("reco 3 cta primary");
                  }}
                >
                  Segment audience
                </a>
              </div>
            </div>
            <img
              srcSet="/images/aui-checklist-3-1x.jpg, /images/aui-checklist-3-2x.jpg 2x, /images/aui-checklist-3-3x.jpg 3x"
              src="/images/aui-checklist-3-3x.jpg"
              alt="Example of Create a Segment feature"
            />
          </div>
        </AccordionItem>
      </Accordion>
    </>
  ) : (
    <div className="checklist-complete">
      <img
        srcSet="/images/illustration-complete-1x.jpg, /images/illustration-complete-2x.jpg 2x, /images/illustration-complete-3x.jpg 3x"
        src="/images/illustration-complete-3x.jpg"
        alt="A cartoon hand giving a thumbs up with extra thumbs"
      />
      <h2>Checklist complete! Nicely done.</h2>
      <p>
        Visit your{" "}
        <a
          href="https://admin.mailchimp.com/integrations/manage/?name=square"
          target="_blank"
          rel="noreferrer"
        >
          Mailchimp Dashboard
        </a>{" "}
        to explore more ways to grow customer relationships or{" "}
        <button
          type="button"
          className="link"
          onClick={() => {
            setActiveRecommendation(null);
            setShowRecommendations(true);
          }}
        >
          view our recommendations
        </button>{" "}
        again.
      </p>
    </div>
  );
};

RecommendationsChecklist.propTypes = {
  completedRecommendations: PropTypes.arrayOf(PropTypes.string),
  recordRecommendationCompletion: PropTypes.func.isRequired,
};

export default RecommendationsChecklist;
