import React from "react";
import { useSelector } from "react-redux";
import UpdateLogins from "../components/update-logins";
import { useLoadSessionQuery } from "../features/mc-square-api/mc-square-api-slice";

const UpdateLoginsContainer = () => {
  const { authenticityToken } = useSelector((state) => state.session);

  const { data: squareUser } = useLoadSessionQuery();

  return (
    <UpdateLogins
      squareUser={squareUser}
      authenticityToken={authenticityToken}
    />
  );
};

UpdateLoginsContainer.propTypes = {};

export default UpdateLoginsContainer;
