import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Feedback from "./feedback";

const MergeFieldEditFeedback = ({ showSuccess, showErrors }) => {
  const navigate = useNavigate();

  return (
    <>
      {showSuccess && (
        <Feedback status="success" label="Success">
          Successfully saved your changes! Now we&apos;re updating your
          customers. Check the{" "}
          <button
            type="button"
            className="link"
            onClick={() => {
              navigate("/sync-logs");
            }}
          >
            sync logs
          </button>{" "}
          to track progress.
        </Feedback>
      )}

      {showErrors && (
        <Feedback status="error" label="Validation Errors">
          You have one or more errors in the merge fields form. Check the form
          for the specific errors that occurred.
        </Feedback>
      )}
    </>
  );
};

MergeFieldEditFeedback.propTypes = {
  showErrors: PropTypes.bool.isRequired,
  showSuccess: PropTypes.bool.isRequired,
};

export default MergeFieldEditFeedback;
