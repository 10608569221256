import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import InfoCircleFillIcon from "./icons/info-circle-fill-icon";
import Spinner from "./spinner";

const trackTooltipView = () =>
  ReactGA.event({
    category: "dashboard",
    action: "click",
    label: "tooltip",
  });

const DataItem = ({ label, value = undefined, tooltip, loading }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className="data-well-item">
      <p className="value">
        {loading ? <Spinner /> : value?.toLocaleString() || "No Data Available"}
      </p>
      <p className="label">
        <span>{label}</span>
        <button
          className="wink-button"
          type="button"
          onClick={() => {
            setTooltipVisible(true);
            trackTooltipView();
          }}
          onBlur={() => setTooltipVisible(false)}
        >
          <span className="visually-hidden">{label}</span>
          <InfoCircleFillIcon />
          {tooltipVisible && <div className="tooltip">{tooltip}</div>}
        </button>

        <span role="status" aria-atomic="true">
          <span className="wink-visually-hidden" />
        </span>
      </p>
    </div>
  );
};

DataItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  tooltip: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DataItem;
