import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import MailchimpList from "./mailchimp-list";
import {
  MAILCHIMP_LIST_PROP_TYPES,
  MUTATION_RESULT_PROP_TYPES,
  SELECTED_MAILCHIMP_LIST_PROP_TYPES,
} from "../constants/proptypes";
import Feedback from "./feedback";
import SpinnerLabel from "./spinner-label";

const trackAudienceSelection = () =>
  ReactGA.event({
    category: "settings screen",
    action: "select",
    label: "change audience",
  });

const trackAudienceSelectionConfirmation = () =>
  ReactGA.event({
    category: "settings screen",
    action: "click",
    label: "confirm audience change",
  });

const AudienceSelection = ({
  lists,
  fetchingLists,
  canCreateList,
  selectedList: initialSelectedList,
  errorLoadingLists,
  createList,
  createListResult,
  saveSelectedList,
  savingSelectedList,
  kickoffInitialSync,
}) => {
  const [selectedList, setSelectedList] = useState(initialSelectedList);

  const navigate = useNavigate();

  return (
    <>
      <h2>Audience</h2>

      <p>
        Your Square data and customers will be synced into the selected
        audience.
      </p>

      <MailchimpList
        lists={lists}
        fetchingLists={fetchingLists}
        canCreateList={canCreateList}
        selectedList={selectedList}
        errorLoadingLists={errorLoadingLists}
        createList={createList}
        createListResult={createListResult}
        onSelectedListChange={(selectedList) => {
          setSelectedList(selectedList);
          trackAudienceSelection();
        }}
      />

      <form>
        <Feedback label="Changing the Mailchimp List" status="warning">
          Changing the Mailchimp audience{" "}
          <em>
            removes all existing Square store data, including product, order,
            and customer information
          </em>
          . A new store will be created in Mailchimp and Square data will be
          re-synced to the newly selected audience.
        </Feedback>

        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={async () => {
              await saveSelectedList(selectedList);
              await kickoffInitialSync({ initial: true });
              navigate("/");
              trackAudienceSelectionConfirmation();
            }}
            disabled={
              !selectedList?.remoteListId ||
              selectedList?.remoteListId === initialSelectedList.remoteListId ||
              savingSelectedList
            }
          >
            {savingSelectedList ? (
              <SpinnerLabel label="Change selected audience" />
            ) : (
              "Change selected audience"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

AudienceSelection.propTypes = {
  lists: PropTypes.arrayOf(MAILCHIMP_LIST_PROP_TYPES).isRequired,
  fetchingLists: PropTypes.bool.isRequired,
  canCreateList: PropTypes.bool.isRequired,
  selectedList: SELECTED_MAILCHIMP_LIST_PROP_TYPES.isRequired,
  errorLoadingLists: PropTypes.bool.isRequired,
  createList: PropTypes.func.isRequired,
  createListResult: MUTATION_RESULT_PROP_TYPES.isRequired,
  saveSelectedList: PropTypes.func.isRequired,
  savingSelectedList: PropTypes.bool.isRequired,
  kickoffInitialSync: PropTypes.func.isRequired,
};

export default AudienceSelection;
