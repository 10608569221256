import React from "react";
import { SYNC_PROP_TYPES } from "../constants/proptypes";
import SyncBatchStatus from "./sync-batch-status";

const SyncStatus = ({ sync = null }) => {
  if (!sync) return null;

  return (
    <div>
      <div>
        {sync.flattenedSyncBatchStatuses.map((syncBatchStatus) => (
          <SyncBatchStatus
            key={syncBatchStatus.task}
            syncBatchStatus={syncBatchStatus}
          />
        ))}
      </div>
    </div>
  );
};

SyncStatus.propTypes = { sync: SYNC_PROP_TYPES };

export default SyncStatus;
