import React from "react";
import Overview from "../components/overview";
import {
  useGetCompletedRecommendationsQuery,
  useUpdateCompletedRecommendationsMutation,
} from "../features/mc-square-api/mc-square-api-slice";

const OverviewContainer = () => {
  const {
    data: completedRecommendations,
    isLoading: loadingCompletedRecommendations,
  } = useGetCompletedRecommendationsQuery();

  const [updateCompletedRecommendations] =
    useUpdateCompletedRecommendationsMutation();

  return (
    <Overview
      completedRecommendations={completedRecommendations || []}
      loadingCompletedRecommendations={loadingCompletedRecommendations}
      updateCompletedRecommendations={updateCompletedRecommendations}
    />
  );
};

OverviewContainer.propTypes = {};

export default OverviewContainer;
