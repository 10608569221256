import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SyncDetails from "../components/sync-details";
import {
  useGetSyncErrorsQuery,
  useGetSyncQuery,
} from "../features/mc-square-api/mc-square-api-slice";
import { setSelectedSyncId } from "../features/mc-square/mc-square-slice";

const SyncDetailsContainer = () => {
  const dispatch = useDispatch();
  const [pollingInterval, setPollingInterval] = useState(0);
  const [page, setPage] = useState(1);
  const [includeResolved, setIncludeResolved] = useState(false);
  const selectedSyncId = useSelector(({ mcSquare }) => mcSquare.selectedSyncId);

  const { data: sync } = useGetSyncQuery(
    { id: selectedSyncId },
    {
      pollingInterval,
      skip: !selectedSyncId,
    }
  );

  const { data: { data: syncErrors, pagination, hasResolvedErrors } = {} } =
    useGetSyncErrorsQuery(
      {
        syncId: selectedSyncId,
        includeResolved,
        page,
      },
      {
        pollingInterval,
        skip: !selectedSyncId,
      }
    );

  const needsPollingForNewSync = !!syncErrors?.find(
    (syncError) =>
      syncError.manualRetrySyncBatchId && !syncError.manualRetrySyncId
  );

  useEffect(() => {
    if (
      sync?.status === "in_progress" ||
      sync?.status === "retrying" ||
      needsPollingForNewSync
    ) {
      setPollingInterval(2000);
    } else {
      setPollingInterval(0);
    }
  }, [sync?.status, needsPollingForNewSync]);

  if (!selectedSyncId) return null;

  return (
    <SyncDetails
      sync={sync}
      close={async () => {
        await dispatch(setSelectedSyncId(null));
        setPage(1);
        setIncludeResolved(false);
      }}
      syncErrors={syncErrors}
      pagination={pagination}
      setPage={setPage}
      includeResolved={includeResolved}
      setIncludeResolved={setIncludeResolved}
      setSelectedSyncId={(syncId) => dispatch(setSelectedSyncId(syncId))}
      hasResolvedErrors={hasResolvedErrors}
    />
  );
};

SyncDetailsContainer.propTypes = {};

export default SyncDetailsContainer;
