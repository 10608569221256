import React from "react";
import PropTypes from "prop-types";

const SettingsIcon = ({
  alt = "Settings",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="SettingsIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="SettingsIcon">{alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.988 1a2 2 0 00-1.944 1.533l-.397 1.654a8.5 8.5 0 00-2.102 1.294l-1.77-.525a2 2 0 00-2.3.918L1.463 7.626a2 2 0 00.356 2.45l1.687 1.602a8.646 8.646 0 00.019.974l-1.706 1.62a2 2 0 00-.356 2.45l1.012 1.752a2 2 0 002.3.918l2.088-.619a8.492 8.492 0 001.784 1.04l.397 1.654A2 2 0 0010.988 23h2.024a2 2 0 001.944-1.533l.397-1.654a8.496 8.496 0 001.784-1.04l2.088.619a2 2 0 002.3-.918l1.012-1.752a2 2 0 00-.355-2.45l-1.707-1.62a8.59 8.59 0 00.019-.974l1.688-1.601a2 2 0 00.355-2.45l-1.012-1.753a2 2 0 00-2.3-.918l-1.77.525a8.502 8.502 0 00-2.102-1.294l-.397-1.654A2 2 0 0013.012 1h-2.024zm0 2h2.024l.645 2.69.512.18a6.5 6.5 0 012.34 1.449l.42.404 2.864-.85 1.012 1.753-2.381 2.26.046.485a6.595 6.595 0 01-.032 1.53l-.07.508 2.437 2.313-1.012 1.752-3.119-.924-.414.36a6.506 6.506 0 01-2.091 1.22l-.512.18L13.01 21h-2.023l-.645-2.69-.512-.18a6.494 6.494 0 01-2.091-1.22l-.414-.36-3.119.924-1.012-1.752 2.437-2.312-.07-.51a6.567 6.567 0 01-.032-1.53l.047-.485-2.382-2.259 1.012-1.752 2.864.849.42-.405A6.5 6.5 0 019.83 5.87l.512-.18.645-2.69z"
    />
  </svg>
);

SettingsIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default SettingsIcon;
