import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import SpinnerLabel from "./spinner-label";
import Modal from "./modal";
import paths from "../services/paths-service";

const trackEnablingDeletingRemoteData = (enabled) => {
  if (!enabled) return;

  ReactGA.event({
    category: "settings screen",
    action: "check box",
    label: "delete store data checkbox",
  });
};

const trackDisconnectDialogDisplayed = () =>
  ReactGA.event({
    category: "settings screen",
    action: "click",
    label: "disconnect the app",
  });

const Disconnect = ({ disconnect, disconnecting = false }) => {
  const [
    showDisconnectConfirmationDialog,
    setShowDisconnectConfirmationDialog,
  ] = useState(false);
  const [deleteRemoteData, setDeleteRemoteData] = useState(false);

  return (
    <div>
      <h2>Disconnect</h2>

      <p>You can disconnect and stop syncing data from Square to Mailchimp.</p>

      <form>
        <fieldset>
          <div className="wink-form-control-container">
            <label className="wink-form-control-label" id="disconnect">
              <input
                type="checkbox"
                className="wink-form-control wink-form-control-checkbox"
                checked={deleteRemoteData}
                onChange={(e) => {
                  setDeleteRemoteData(e.target.checked);
                  trackEnablingDeletingRemoteData(e.target.checked);
                }}
              />
              Delete Store Data at Mailchimp
            </label>
            <p aria-describedby="disconnect" className="wink-form-description">
              Deleting Store Data at Mailchimp will delete the Square store
              association and all products and orders that have been synced.{" "}
              <strong>
                This will <em>not</em> impact any data at Square!
              </strong>
            </p>
          </div>
        </fieldset>

        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={() => {
              setShowDisconnectConfirmationDialog(true);
              trackDisconnectDialogDisplayed();
            }}
          >
            <span className="below-small">Disconnect</span>
            <span className="above-small">
              Disconnect from Square and Mailchimp
            </span>
          </button>
        </div>
      </form>

      <Modal
        isOpen={showDisconnectConfirmationDialog}
        close={() => setShowDisconnectConfirmationDialog(false)}
      >
        <h3>Do you really want to disconnect?</h3>
        <p>
          Please confirm that you wish to disconnect from Square and Mailchimp.
          This will stop syncs from running and remove the application at
          Square, but not at Mailchimp.
        </p>
        {deleteRemoteData ? (
          <p>
            You have selected to delete all synced store data from Mailchimp.
            This will delete the Square store association, all products,
            customers, and orders that have been synced.{" "}
            <strong>
              This will <em>not</em> impact any data at Square!
            </strong>{" "}
          </p>
        ) : (
          <p>
            You have not selected to remove synced store data from Mailchimp.
            All synced products, customers, and orders will remain.
          </p>
        )}

        <p>
          Clicking the disconnect button will immediately disconnect the
          integration and log you out.
        </p>

        <form>
          <div className="wink-form-field-container action-buttons">
            <button
              type="button"
              className="wink-button wink-button-secondary"
              onClick={() => setShowDisconnectConfirmationDialog(false)}
              disabled={disconnecting}
            >
              Cancel
            </button>
            <button
              type="button"
              className="wink-button wink-button-primary"
              onClick={async () => {
                await disconnect(deleteRemoteData);
                document.location.href = paths.logoutPath;
              }}
              disabled={disconnecting}
            >
              {disconnecting ? (
                <SpinnerLabel label="Disconnect" />
              ) : (
                "Disconnect"
              )}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

Disconnect.propTypes = {
  disconnecting: PropTypes.bool,
  disconnect: PropTypes.func.isRequired,
};

export default Disconnect;
