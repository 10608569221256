import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import creatableSelectStyles from "../constants/creatable-select-styles";
import {
  MERGE_FIELD_MAPPING,
  SQUARE_MERGE_FIELD,
} from "../constants/proptypes";

const SquareMergeFieldSelect = ({
  squareMergeFields,
  mergeFieldMapping,
  onChange = () => {},
  isDisabled = false,
  invalid = false,
}) => {
  const squareMergeFieldToOption = (squareMergeField) => ({
    value: squareMergeField,
    label: Object.values(squareMergeFields[squareMergeField] || {}).join(),
    ...squareMergeFields[squareMergeField],
  });

  const squareMergeFieldOptions = Object.keys(squareMergeFields).map(
    squareMergeFieldToOption
  );

  return (
    <Select
      isClearable
      isSearchable
      isDisabled={isDisabled}
      styles={creatableSelectStyles({ invalid })}
      id="square-field-list"
      options={squareMergeFieldOptions}
      value={squareMergeFieldToOption(mergeFieldMapping.squareField)}
      onChange={onChange}
      formatOptionLabel={(option, { context }) => {
        if (context === "menu") {
          return (
            <>
              <div>
                <strong>{option.text}</strong>
              </div>
              <div>{option.description}</div>
            </>
          );
        }

        return option.text;
      }}
    />
  );
};

SquareMergeFieldSelect.propTypes = {
  squareMergeFields: SQUARE_MERGE_FIELD.isRequired,
  mergeFieldMapping: MERGE_FIELD_MAPPING.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default SquareMergeFieldSelect;
