import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import ReactGA from "react-ga4";
import { PAGINATION_TYPE, SYNCS_PROP_TYPE } from "../constants/proptypes";
import SpinnerLabel from "./spinner-label";
import IconLabel from "./icon-label";
import TickCircleIcon from "./icons/tick-circle-icon";
import CrossCircleIcon from "./icons/cross-circle-icon";
import MenuLeftIcon from "./icons/menu-left-icon";
import MenuRightIcon from "./icons/menu-right-icon";
import friendlyTaskName from "../services/worker-translator";

const trackShowSyncDetails = () =>
  ReactGA.event({
    category: "sync logs",
    action: "click",
    label: "see sync details",
  });

const SyncLogsTable = ({
  syncs = null,
  pagination = null,
  gettingSyncs,
  setPage,
  setSelectedSyncId,
}) =>
  syncs && !gettingSyncs ? (
    <div>
      <table className="sync-logs">
        <thead>
          <tr>
            <th className="hide-when-small">Started</th>
            <th>Duration</th>
            <th>Sync Type</th>
            <th>Status</th>
            <th>Alerts</th>
          </tr>
        </thead>
        <tbody>
          {syncs.map((sync) => (
            <tr
              key={sync.id}
              className="sync-logs-row"
              onClick={() => {
                setSelectedSyncId(sync.id);
                trackShowSyncDetails();
              }}
            >
              <td
                className="hide-when-small"
                title={new Intl.DateTimeFormat("default", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }).format(Date.parse(sync.createdAt))}
              >
                {sync.createdAgo} ago
              </td>
              <td>{sync.duration}</td>
              <td>
                {friendlyTaskName(sync.task)} {sync.initial && "(Initial)"}
              </td>
              <td>
                <button type="button" className="link no-underline">
                  {
                    {
                      in_progress: <SpinnerLabel label="In progress" />,
                      retrying: <SpinnerLabel label="In progress" />,
                      succeeded: (
                        <IconLabel label="Succeeded" icon={TickCircleIcon} />
                      ),
                      failed: (
                        <IconLabel label="Failed" icon={CrossCircleIcon} />
                      ),
                      canceled: (
                        <IconLabel label="Canceled" icon={CrossCircleIcon} />
                      ),
                    }[sync.status]
                  }
                </button>
              </td>
              <td>
                <ul>
                  {sync.problems.ignored > 0 && (
                    <li>
                      {sync.problems.ignored}{" "}
                      {sync.problems.ignored === 1 ? "item" : "items"} skipped
                    </li>
                  )}
                  {sync.problems.retrying > 0 && (
                    <li>
                      {sync.problems.retrying}{" "}
                      {sync.problems.retrying === 1 ? "item" : "items"} retrying
                    </li>
                  )}
                  {sync.problems.failed > 0 && (
                    <li>
                      {sync.problems.failed}{" "}
                      {sync.problems.failed === 1 ? "item" : "items"} failed
                    </li>
                  )}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="sync-logs-footer">
        <ReactPaginate
          containerClassName="pagination"
          pageCount={pagination.pages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          previousLinkClassName="wink-button"
          nextLinkClassName="wink-button"
          pageLinkClassName="wink-button"
          breakLinkClassName="wink-button"
          activeLinkClassName="wink-button-primary"
          disabledClassName="disabled"
          previousLabel={<MenuLeftIcon />}
          nextLabel={<MenuRightIcon />}
          onPageChange={({ selected }) => setPage(selected + 1)}
        />
      </div>
    </div>
  ) : (
    <SpinnerLabel label="Loading sync logs..." />
  );

SyncLogsTable.propTypes = {
  syncs: SYNCS_PROP_TYPE,
  pagination: PAGINATION_TYPE,
  gettingSyncs: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  setSelectedSyncId: PropTypes.func.isRequired,
};

export default SyncLogsTable;
